import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

import styles from './styles';
import { getDirname } from '../../../utils/parseQuery';

const useStyles = makeStyles(styles, { name: getDirname(__dirname) });

const Divider = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.border} />
      <span className={classes.content}>{children}</span>
      <div className={classes.border} />
    </div>
  );
};

Divider.propTypes = {
  children: PropTypes.node,
};

Divider.defaultProps = {
  children: null,
};

export default Divider;
