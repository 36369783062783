const fields = {
  emailLabel: 'Email',
  passwordLabel: 'Password',
  confirmPasswordLabel: 'Retype password',
  nameLabel: 'Name (optional)',
  verificationCodeLabel: 'Verification code',
  resetCodeLabel: 'Reset code',
  placeholders: {
    emailPlaceholder: 'john.smith@mail.com',
    passwordPlaceholder: '••••••••',
    namePlaceholder: 'Provide your first and last name',
    phonePlaceholder: 'Fill your phone number',
    countryPlaceholder: 'Choose your country',
  },
};

export default fields;
