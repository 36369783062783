import React from 'react';
import { branch, compose, renderComponent } from 'recompose';

import Loader from '../../components/UI/Loader';

export default function withLoader(checker = ({ loading }) => Boolean(loading)) {
  return function createEnhancer(WrappedComponent) {
    return compose(
      branch(
        checker,
        renderComponent(() => (
          <div style={{ height: '100%', width: '100%' }}>
            <Loader />
          </div>
        )),
      ),
    )(WrappedComponent);
  };
}
