const getFormattedProductName = (name) => {
  const lowName = name.toLowerCase();
  switch (lowName) {
    case 'wisnode':
      return 'WisNode TrackIt';
    case 'rakreleasedownloadcenter':
      return 'SDK portal';
    default:
      return name;
  }
};

export default getFormattedProductName;
