const products = {
  appsAndServicesTitle: 'Manage apps and services',
  productsPageSubTitle:
    "Manage all your apps and services from a single, central place. Choose 'Remove' when you no longer need a specific app or service, and we'll have all your data deleted as well.",
  productsPageEmpty: 'You have no linked accounts.',
  productsPageOpenBtn: 'Open app',
  productsPageDeleteBtn: 'Remove',
}

export default products
