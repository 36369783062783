import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import parseQuery from '../../utils/parseQuery';

const useProductType = () => {
  const { search } = useLocation();
  const { external: isExternalProduct, platform } = parseQuery(search);

  const isInternalProduct = useMemo(
    () => platform && !isExternalProduct,
    [isExternalProduct, platform],
  );

  return useMemo(
    () => ({ isExternalProduct, isInternalProduct }),
    [isExternalProduct, isInternalProduct],
  );
};

export default useProductType;
