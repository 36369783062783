import { textMixin, textSizes } from '../../../app/theme/mixins';

export default function styles(theme) {
  return {
    modalContent: {
      width: '550px',

      [theme.breakpoints.down('sm')]: {
        width: 'auto',
      },
    },
    container: {
      maxWidth: '420px',
      width: '420px',
      margin: '0 auto',

      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    hint: {
      ...textMixin({
        size: textSizes.base,
        opacity: 1,
        color: theme.custom.textColor,
      }),
      textAlign: 'center',
      marginBottom: '12px',
    },
    warningWrap: {
      marginBottom: '12px',
    },
    card: {
      padding: '36px 46px 20px',
      textAlign: 'center',
      background: theme.custom.backgroundColor,
      borderRadius: '8px',

      [theme.breakpoints.down('sm')]: {
        background: 'transparent',
        padding: '28px 0',
      },
    },
    label: {
      ...textMixin({
        opacity: 0.5,
        color: theme.custom.textColor,
        size: textSizes.small,
      }),
      fontSize: '10px',
      fontWeight: 500,
      letterSpacing: '1px',
      textTransform: 'uppercase',
      marginBottom: '12px',
    },
    faCodeFieldWrap: {
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    inputWrap: {
      background: theme.custom.whiteColor,
      marginBottom: '32px',

      '& input': {
        fontSize: '16px',
        lineHeight: 'normal',
        color: `${theme.custom.blackColor} !important`,
        '-webkit-text-fill-color': theme.custom.blackColor,
      },
    },
    dividerWrap: {
      margin: '32px 0 12px',
    },
    toggleButton: {
      fontSize: '10px',
      fontWeight: 500,
      letterSpacing: '0.16em',
      textTransform: 'uppercase',
    },
    supportLinkText: {
      textDecoration: 'underline',
    },
  };
}
