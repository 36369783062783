import { createSelector } from 'reselect';

const userProfileState = (state) => state.userProfile;

const UserProfileSelectors = {
  getUserProfile() {
    return createSelector(userProfileState, (userProfile) => userProfile);
  },
  getConnectedProducts() {
    return createSelector(userProfileState, (profile) => profile?.connectedProducts || []);
  },
  getIsMFAEnabled() {
    return createSelector(userProfileState, (profile) => profile?.mfa || false);
  },
  getRoles() {
    return createSelector(userProfileState, (profile) => profile?.roles || []);
  },
};

export default UserProfileSelectors;
