import httpRequest from './httpRequest';
import cache from '../utils/cache';

function configApiRequest(reqBaseConfig, meta) {
  const accessToken = cache.getAccessToken();

  const requestHeaders = {
    'Content-Type': 'application/json',
  };

  if (meta.authRequired) {
    requestHeaders.Authorization = `Bearer ${accessToken}`;
  }

  return httpRequest({
    data: {},
    ...reqBaseConfig,
    headers: requestHeaders,
  });
}

export default configApiRequest;
