import { put, takeEvery } from 'redux-saga/effects';
import API_REQ from '../../api/apiActions';
import cache from '../../api/utils/cache';

function* triggerRefreshToken() {
  const refreshToken = cache.getRefreshToken();
  yield put(API_REQ.AUTH.TOKEN_EXPIRED(refreshToken));
}

export default function* userProfileSaga() {
  yield takeEvery(API_REQ.PROFILE.DISCONNECT.SUCCESS, triggerRefreshToken);
}
