import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';

import AutoLogoutModal from '../../../components/modals/AutoLogoutModal';
import Footer from '../../../components/pageElements/Footer';
import Header from '../../../components/pageElements/Header';
import useMobileScreenDetect from '../../hooks/useMobileScreenDetect';
import Sidebar from '../../../components/pageElements/Sidebar';
import withLoader from '../../hocs/withLoader';
import UserProfileSelectors from '../../../pages/UserProfile/selectors';
import styles from './styles';
import { getDirname } from '../../../utils/parseQuery';

const useStyles = makeStyles(styles, { name: getDirname(__dirname) });

const AuthorizeLayout = ({ children }) => {
  const classes = useStyles();

  const { pathname } = useLocation();
  const { isMobile } = useMobileScreenDetect();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <div className={classes.authLayout}>
        <Header isMobile={isMobile} />

        <div className={classes.authContent}>
          <aside className={classes.authAside}>
            <Sidebar withProfile />
          </aside>
          <main className={classes.authMain}>{children}</main>
        </div>

        <footer className={classes.authFooter}>
          <Footer />
        </footer>
      </div>

      <AutoLogoutModal />
    </>
  );
};

AuthorizeLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default compose(
  connect((state) => ({
    userProfile: UserProfileSelectors.getUserProfile()(state),
  })),
  withRouter,
  withLoader(({ userProfile }) => !userProfile),
)(AuthorizeLayout);
