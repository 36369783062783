const forgotPassword = {
  forgotPasswordTitle: 'Forgot password?',
  forgotPasswordSubTitle:
    'Enter your RAK ID and we’ll send you{br}instructions to reset your password.',
  forgotPasswordButtonEmailMe: 'Reset password',
  forgotPasswordButtonSignIn: 'Back to sign in',
  userNotFound: 'User not found',
};

export default forgotPassword;
