import { textMixin, textSizes } from '../../../app/theme/mixins';

export default function styles(theme) {
  return {
    appBar: {
      backgroundColor: theme.custom.whiteColor,
      borderBottom: `1px solid ${theme.custom.getDarkRgbaColor(0.1)}`,
      boxShadow: 'none',
    },
    toolbar: {
      minHeight: '88px',
      paddingLeft: '50px',
      paddingRight: '50px',
      justifyContent: 'space-between',

      [theme.breakpoints.down('sm')]: {
        minHeight: '60px',
        paddingLeft: '20px',
        paddingRight: '20px',
      },
    },
    logoWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    singlePageLogoWrapper: {
      [theme.breakpoints.down('sm')]: {
        flex: 1,
        justifyContent: 'center',
        marginLeft: '-30px',
      },
    },
    logo: {
      width: '98px',
      height: '28px',

      [theme.breakpoints.down('sm')]: {
        width: '70px',
        height: '20px',
      },
    },
    logoText: {
      ...textMixin({
        size: textSizes.xxLarge,
        color: theme.custom.textColor,
      }),
      fontSize: '27px',
      letterSpacing: '-0.02em',
      paddingLeft: '9px',

      [theme.breakpoints.down('sm')]: {
        ...textMixin({
          size: textSizes.large,
          color: theme.custom.textColor,
        }),
      },
    },
  };
}
