import { getCookie, setCookie } from './cookies';

const PREFIX = 'RAKID.';

const ACCESS_TOKEN_COOKIES_STORAGE_ITEM = `${PREFIX}accessToken`;
const REFRESH_TOKEN_COOKIES_STORAGE_ITEM = `${PREFIX}refreshToken`;
const ID_TOKEN_COOKIES_STORAGE_ITEM = `${PREFIX}idToken`;
const IS_USER_ACTIVE_COOKIES_STORAGE_ITEM = `${PREFIX}isUserActive`;
const EMAIL_LOCAL_STORAGE_ITEM = 'userEmail';

const cache = {
  getEmail() {
    return window.localStorage.getItem(EMAIL_LOCAL_STORAGE_ITEM) || '';
  },

  setEmail(value) {
    window.localStorage.setItem(EMAIL_LOCAL_STORAGE_ITEM, value);
  },

  getIsUserActive() {
    return getCookie(IS_USER_ACTIVE_COOKIES_STORAGE_ITEM) || '';
  },

  setIsUserActive(value) {
    setCookie(IS_USER_ACTIVE_COOKIES_STORAGE_ITEM, value);
  },

  getAccessToken() {
    return getCookie(ACCESS_TOKEN_COOKIES_STORAGE_ITEM) || '';
  },

  setAccessToken(value) {
    setCookie(ACCESS_TOKEN_COOKIES_STORAGE_ITEM, value);
  },

  getRefreshToken() {
    return getCookie(REFRESH_TOKEN_COOKIES_STORAGE_ITEM) || '';
  },

  setRefreshToken(value) {
    setCookie(REFRESH_TOKEN_COOKIES_STORAGE_ITEM, value);
  },

  getIdToken() {
    return getCookie(ID_TOKEN_COOKIES_STORAGE_ITEM) || '';
  },

  setIdToken(value) {
    setCookie(ID_TOKEN_COOKIES_STORAGE_ITEM, value);
  },
};

export default cache;
