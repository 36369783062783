import get from 'lodash.get';
import { call, put } from 'redux-saga/effects';

import API_REQ from '../apiActions';
import createAsyncActions from '../utils/createAsyncActions';
import configApiRequest from './configApiRequest';

export default function* xhrSaga(reqConfig, meta) {
  const XHR = createAsyncActions(API_REQ.XHR);

  yield put(XHR.start(reqConfig, meta));

  try {
    const request = yield call(configApiRequest, reqConfig, meta);

    const response = yield call(() => request);

    const data = get(response, ['data']);

    yield put(XHR.success(data, meta));

    return {
      ...response,
      data,
    };
  } catch (error) {
    const status = get(error, ['response', 'status']);
    const errors = get(error, ['response', 'data']);

    yield put(XHR.error({ ...errors, status }, meta));

    const response = {
      data: errors,
    };

    if (!error.response) {
      return response;
    }

    return {
      ...error.response,
      ...response,
    };
  }
}
