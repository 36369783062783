import getEntries from '../../utils';

import confirmPassword from './confirmPassword';
import forgotPassword from './forgotPassword';
import deleteProfile from './deleteProfile';
import registration from './registration';
import verifyEmail from './verifyEmail';
import profileInfo from './profileInfo';
import validations from './validations';
import connection from './connection';
import disconnect from './disconnect';
import tooltips from './tooltips';
import products from './products';
import security from './security';
import common from './common';
import fields from './fields';
import login from './login';
import about from './about';

const all = {
  confirmPassword,
  forgotPassword,
  deleteProfile,
  registration,
  verifyEmail,
  profileInfo,
  validations,
  connection,
  disconnect,
  products,
  security,
  tooltips,
  fields,
  common,
  login,
  about,
};

export default Object.fromEntries(getEntries(all));
