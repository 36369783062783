import React from 'react';
import { toast } from 'react-toastify';

import StyledToast from '../components/UI/Notification/StyledToast';
import ReduxProvider from './providers/ReduxProvider';
import HistoryProvider from './providers/HistoryProvider';
import LocalesProvider from './providers/LocalesProvider';
import MUIThemeProvider from './providers/MuiThemeProvider';
import AppRoutes from './routes';

import './index.css';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

function App() {
  return (
    <ReduxProvider>
      <HistoryProvider>
        <LocalesProvider>
          <MUIThemeProvider>
            <AppRoutes />
            <StyledToast />
          </MUIThemeProvider>
        </LocalesProvider>
      </HistoryProvider>
    </ReduxProvider>
  );
}

export default App;
