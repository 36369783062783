export default function styles(theme) {
  return {
    authLayout: {
      display: 'flex',
      position: 'relative',
      minWidth: '100%',
      minHeight: '100%',
      alignItems: 'stretch',
      flexDirection: 'column',
    },
    authContent: {
      position: 'relative',
      display: 'flex',
      flexGrow: 1,
      alignItems: 'stretch',
      flexShrink: 1,
      padding: '149px 112px 60px',

      [theme.breakpoints.down('md')]: {
        padding: '149px 20px 20px',
      },

      [theme.breakpoints.down('sm')]: {
        padding: '100px 20px 20px',
      },
    },
    authAside: {
      flexBasis: '274px',
      flexGrow: 1,
      marginRight: '20px',
      maxWidth: '30%',

      '@media (max-width: 1150px)': {
        maxWidth: 'fit-content',
      },

      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    authMain: {
      maxWidth: '680px',
      flexGrow: 1,
      flexShrink: 1,
      alignItems: 'stretch',

      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
    },
    authFooter: {
      padding: '60px 112px',

      [theme.breakpoints.down('sm')]: {
        padding: '40px 20px',
      },
    },
  };
}
