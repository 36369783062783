import history from '../app/redux/configureBrowserHistory';
import parseQuery from './parseQuery';

export default function getProductType() {
  const { search } = history.location;
  const { external: isExternalProduct, platform } = parseQuery(search);
  const isInternalProduct = platform && !isExternalProduct;

  return { isInternalProduct, isExternalProduct };
}
