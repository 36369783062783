import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { clearCache } from '../../api/apiSaga/authCache';
import RoutePaths from '../../app/routes/routePaths';

const ForceLogout = () => {
  const { replace } = useHistory();
  const { search } = useLocation();

  useEffect(() => {
    clearCache();
    replace({ pathname: RoutePaths.Auth.Login._(), search });
  }, [replace, search]);

  return <div />;
};

export default ForceLogout;
