import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';

import Auth from '../../../api/apiEndpoints/Auth';
import useModal from '../../../app/hooks/useModal';
import { runSaga } from '../../../app/redux/configureStore';
import AuthSelectors from '../../../pages/Auth/selectors';
import useMFAErrorHandler from '../../../pages/Security/containers/MFA/hooks/useMFAErrorHandler';
import { FA_LOGIN_TYPE } from './constants';

const useMFAConfirm = () => {
  const MFASignInSession = useSelector(AuthSelectors.getMFASignInSession());
  const { open, handleCloseModal, handleOpenModal } = useModal(false);
  const { errorActions, errorStatus, clearErrorStatus } = useMFAErrorHandler();
  const loginFormValues = useSelector(getFormValues('LOGIN_FORM'));
  const [type, setType] = useState(FA_LOGIN_TYPE.FA_CODE);

  const handleSubmit = useCallback(
    async ({ code = '' }) => {
      clearErrorStatus();

      let sagaName = Auth.MFA.login;
      const data = {
        code,
        email: loginFormValues?.email,
      };

      if (type === FA_LOGIN_TYPE.FA_CODE) {
        data.session = MFASignInSession;
      }

      if (type === FA_LOGIN_TYPE.RESCUE_CODE) {
        sagaName = Auth.MFA.recoveryLogin;
        data.password = loginFormValues?.password;
      }

      const { ok, result } = await runSaga(sagaName, data).toPromise();

      if (ok) {
        handleCloseModal();
      } else {
        const errorAction = errorActions[result?.code] || errorActions.default;
        errorAction(result);
      }
    },
    [MFASignInSession, clearErrorStatus, errorActions, handleCloseModal, loginFormValues, type],
  );

  const toggleType = useCallback(() => {
    clearErrorStatus();
    if (type === FA_LOGIN_TYPE.FA_CODE) {
      return setType(FA_LOGIN_TYPE.RESCUE_CODE);
    }
    return setType(FA_LOGIN_TYPE.FA_CODE);
  }, [clearErrorStatus, type]);

  useEffect(() => {
    if (MFASignInSession) {
      handleOpenModal();
    } else {
      handleCloseModal();
    }
  }, [MFASignInSession, handleCloseModal, handleOpenModal]);

  useEffect(() => {
    if (!open) {
      clearErrorStatus();
    }
  }, [clearErrorStatus, open]);

  useEffect(() => () => clearErrorStatus, [clearErrorStatus]);

  return {
    open,
    handleCloseModal,
    handleSubmit,
    errorStatus,
    clearErrorStatus,
    toggleType,
    type,
  };
};

export default useMFAConfirm;
