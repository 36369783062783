import React from 'react';
import { makeStyles } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import ButtonBase from '@material-ui/core/ButtonBase';

import { useDispatch } from 'react-redux';
import { submit } from 'redux-form';
import closeIcon from '../../../assets/icons/crosses/close.svg';
import CustomButton from '../../UI/CustomButton';
import customButtonSizes from '../../UI/CustomButton/constants';
import styles from './styles';
import CUSTOM_MODAL_PROP_TYPES from './types';
import { getDirname } from '../../../utils/parseQuery';

const useStyles = makeStyles(styles, { name: getDirname(__dirname) });

const CLOSE_MODAL_REASONS = ['backdropClick', 'escapeKeyDown'];

const CustomModal = ({
  contentClass,
  children,
  modalTrigger,
  titleId,
  submitText,
  cancelText,
  disableSubmit,
  withoutTitle,
  onlyClose,
  showClose,
  isSubmitting,
  handleClose,
  onSubmit,
  isOpen,
  modalProps,
  formName,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.stopPropagation();
    onSubmit(e);
  };

  const handlePreventClick = (e) => e.stopPropagation();

  const getTitle = () =>
    typeof titleId === 'string' ? <FormattedMessage id={titleId} /> : titleId;

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && event.shiftKey === false) {
      event.preventDefault();
      dispatch(submit(formName));
    }
  };

  const closeModal = (e, reason) => {
    if (isSubmitting && CLOSE_MODAL_REASONS.includes(reason)) {
      return;
    }
    e.stopPropagation();
    handleClose(e);
  };

  return (
    <>
      {modalTrigger && <div>{modalTrigger}</div>}
      {isOpen && (
        <Modal
          open={isOpen}
          onClose={closeModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          closeAfterTransition
          className={classes.modal}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          disableScrollLock
          onKeyDown={formName ? handleKeyDown : undefined}
          {...modalProps}
        >
          <Fade in={isOpen}>
            <div
              onClick={handlePreventClick}
              className={classes.wrap}
              onKeyPress={() => {}}
              role="button"
              tabIndex="0"
            >
              <div className={classes.header}>
                {!withoutTitle && (
                  <>
                    <div>{getTitle()}</div>
                    {showClose && (
                      <div
                        className={classes.close}
                        onClick={handleClose}
                        onKeyPress={() => {}}
                        role="button"
                        tabIndex="0"
                      >
                        <img src={closeIcon} alt="icon" />
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className={cn(classes.content, contentClass)}>{children}</div>
              <div className={classes.footer}>
                <div className={classes.buttonBlock}>
                  {onlyClose ? (
                    <CustomButton
                      type="submit"
                      size={customButtonSizes.small}
                      onClick={handleClose}
                      additionalClassName={classes.onlyCloseButton}
                    >
                      <FormattedMessage id={cancelText} />
                    </CustomButton>
                  ) : (
                    <>
                      <CustomButton
                        type="submit"
                        size={customButtonSizes.small}
                        onClick={handleSubmit}
                        loading={isSubmitting}
                        disabled={disableSubmit}
                      >
                        {typeof submitText === 'string' ? (
                          <FormattedMessage id={submitText} />
                        ) : (
                          submitText
                        )}
                      </CustomButton>

                      {showClose && (
                        <ButtonBase
                          onClick={handleClose}
                          className={classes.buttonCancel}
                          disableRipple
                        >
                          <FormattedMessage id={cancelText} />
                        </ButtonBase>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      )}
    </>
  );
};

CustomModal.propTypes = CUSTOM_MODAL_PROP_TYPES.RULES;

CustomModal.defaultProps = CUSTOM_MODAL_PROP_TYPES.DEFAULTS;

export default CustomModal;
