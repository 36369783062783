import React from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';

import TermsPolicyModal from '../../modals/TermsPolicyModal';
import ApiSelectors from '../../../api/selectors';
import styles from './styles';
import { getDirname } from '../../../utils/parseQuery';

const useStyles = makeStyles(styles, { name: getDirname(__dirname) });

const Footer = () => {
  const classes = useStyles();

  const apiVersion = useSelector(ApiSelectors.getApiVersion());
  const year = new Date().getFullYear();

  return (
    <div className={classes.footer}>
      <div>
        <FormattedMessage
          id="common.footer.footerCopyright"
          values={{ version: apiVersion, year }}
        />
      </div>
      <div className={classes.termsWrap}>
        <TermsPolicyModal isFooter />

        <div className={classes.separator}>·</div>

        <TermsPolicyModal isFooter isTerms />
      </div>
    </div>
  );
};

export default Footer;
