import { textMixin, textSizes } from '../../../app/theme/mixins';

export default function styles(theme) {
  return {
    container: {
      display: 'flex',
      alignItems: 'center',
    },
    border: {
      borderBottom: `1px solid ${theme.custom.darkColor}`,
      opacity: 0.1,
      width: '100%',
    },
    content: {
      padding: '0 25px',
      textTransform: 'lowercase',
      ...textMixin({
        opacity: 0.6,
        size: textSizes.small,
        color: theme.custom.textColor,
      }),
    },
  };
}
