import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';

import history from './configureBrowserHistory';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  rootReducer,
  composeWithDevTools(
    applyMiddleware(sagaMiddleware, routerMiddleware(history)),
  ),
);

sagaMiddleware.run(rootSaga);

export const runSaga = (saga, ...args) => sagaMiddleware.run(saga, ...args);

export default store;
