import apiReqSagaCreator from '../apiReqSagaCreator';
import API_REQ from '../apiActions';
import { ApiPaths, HTTP_METHODS } from '../apiConstants';

const Profile = {
  fetch: apiReqSagaCreator(API_REQ.PROFILE.FETCH, (params) => ({
    url: ApiPaths.Profile.Fetch,
    method: HTTP_METHODS.GET,
    params,
  })),
  update: apiReqSagaCreator(API_REQ.PROFILE.UPDATE, (data) => ({
    url: ApiPaths.Profile.Update,
    method: HTTP_METHODS.PUT,
    data,
  })),
  updatePhoto: apiReqSagaCreator(API_REQ.PROFILE.UPDATE_PHOTO, (data) => ({
    url: ApiPaths.Profile.UpdatePhoto,
    method: HTTP_METHODS.PUT,
    data,
  })),
  disconnect: apiReqSagaCreator(API_REQ.PROFILE.DISCONNECT, ({ clientId, ...data }) => ({
    url: `${ApiPaths.Profile.Disconnect}/${clientId}`,
    method: HTTP_METHODS.DELETE,
    data,
  })),
  terminate: apiReqSagaCreator(API_REQ.PROFILE.TERMINATE, (data) => ({
    url: ApiPaths.Profile.Terminate,
    method: HTTP_METHODS.DELETE,
    data,
  })),
  export: apiReqSagaCreator(API_REQ.PROFILE.EXPORT, (params) => ({
    url: ApiPaths.Profile.Export,
    method: HTTP_METHODS.GET,
    params,
  })),
};

export default Profile;
