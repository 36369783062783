import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import cn from 'classnames';

import { getDirname } from '../../../utils/parseQuery';
import { notificationsTypes } from '../Notification/constants';
import styles from './styles';

const { warning: _, ...types } = notificationsTypes;
const NOTIFICATION_TYPES = Object.values(types);

const useStyles = makeStyles(styles, { name: getDirname(__dirname) });

function NotificationBlock({ message, type }) {
  const classes = useStyles();

  const ref = useRef(null);

  useEffect(() => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <div className={cn(classes.root, classes[type])} ref={ref}>
      {Array.isArray(message) ? (
        message.map((msg) => <div key={msg}>{msg}</div>)
      ) : (
        <div>{message}</div>
      )}
    </div>
  );
}

NotificationBlock.propTypes = {
  message: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.array]),
  type: PropTypes.oneOf(NOTIFICATION_TYPES),
};

NotificationBlock.defaultProps = {
  message: 'Error',
  type: notificationsTypes.error,
};

export default NotificationBlock;
