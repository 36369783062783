import React from 'react';
import PropTypes from 'prop-types';
import ReactCodeInput from 'react-verification-code-input';
import { makeStyles } from '@material-ui/core';

import { getDirname } from '../../../utils/parseQuery';
import styles from './styles';

const useStyles = makeStyles(styles, { name: getDirname(__dirname) });

const MFACodeInput = ({ type, fieldsCount, disabled, input }) => {
  const classes = useStyles();
  const values = Array.isArray(input.value) ? input.value : input.value.split('');

  return (
    <ReactCodeInput
      type={type}
      fields={fieldsCount}
      disabled={disabled}
      values={values}
      onChange={input.onChange}
      onComplete={input.onChange}
      className={classes.root}
    />
  );
};

MFACodeInput.propTypes = {
  type: PropTypes.string,
  fieldsCount: PropTypes.number,
  disabled: PropTypes.bool,
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func,
  }),
};

MFACodeInput.defaultProps = {
  type: 'number',
  fieldsCount: 6,
  disabled: false,
  input: {},
};

export default MFACodeInput;
