export default function styles(theme) {
  return {
    errorWrap: {
      marginTop: '-32px',
      marginBottom: '26px',

      '& > div': {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        padding: '8px 20px',
        display: 'flex',
        alignItems: 'center',
      },

      [theme.breakpoints.down('sm')]: {
        marginBottom: '20px',
      },
    },
    errorText: {
      display: 'flex',
      alignItems: 'center',
    },
    errorIcon: {
      paddingRight: '8px',
      '& svg line': {
        stroke: theme.custom.errorColor,
      },
    },
  };
}
