const login = {
  loginSignIn: 'Sign in',
  loginNewUserHint: 'Don’t have an account?',
  loginNewUserLink: 'Create new',
  loginFormForgotPassword: 'I forgot my password',
  loginNotificationBlockExpired:
    'Your session has expired because of inactivity. Please log in again.',
  loginNotificationBlockSuccessLogout: 'You have successfully logged out from your RAK ID.',
  loginNotificationBlockTerminate: 'RAK ID deleted.',
  loginNotificationBlockRateLimit: 'Too many password reset attempts. Try again later.',
  loginNotificationBlockRateLimitValidate: 'Too many failed attempts. Try signing in again.',
  loginNotificationBlockResetPassword: `Password changed! Continue to Sign in.`,
  loginNotificationBlockVerifyEmail: `You have verified your RAK ID. Sign in to continue.`,
  loginNotificationBlockVerifyEmailCredentialsError: `Your account is successfully verified, but the provided password is incorrect. Please sign in to proceed.`,
  loginFormSubmitBtn: 'Sign in',
  loginFormErrorUserBlocked: 'Sorry, your RAK ID is blocked. To learn more, contact our {link}.',
  loginFormErrorUserBlockedLinkText: 'support team',
  loginFormErrorCredentials:
    'Sorry, we couldn’t find an account with this RAK ID and password combination. Try again.',
  loginFormErrorCaptcha:
    'To keep your RAK ID safe, we need you to clear an added security layer of captcha.',
  loginExistedUserProductName: 'to continue to {name}',
  loginExistedUserLink: 'Sign in with another RAK ID',
  loginExistedUserDivider: 'or',
  confirmMFAModal: {
    title: 'Two-factor authentication',
    faCode: {
      toggleBtnText: 'Use recovery code',
      codeText: 'Enter the 6-digit code from your authenticator app',
      codeLabel: '2fa code',
    },
    rescueCode: {
      toggleBtnText: 'Use 2FA CODE',
      codeText: 'Enter an 8-digit recovery code',
      codeLabel: 'Recovery CODE',
      warning:
        "Use recovery code with caution. You'll turn off the 2FA on using the recovery code and must reactivate 2FA to continue protecting your RAK ID. Don't have the recovery code? Contact {support}",
    },
    submitBtnText: 'Authenticate',
  },
  loginPasswordPolicy: 'password policy',
  loginResetYourPassword: 'reset your password',
  loginPasswordPolicyError:
    'Invalid password. As per our latest {passwordPolicy}, you must {resetPassword}.',
};

export default login;
