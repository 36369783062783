export default function styles(theme) {
  return {
    authLayout: {
      display: 'flex',
      position: 'relative',
      minWidth: '100%',
      minHeight: '100%',
      alignItems: 'stretch',
      flexDirection: 'column',
    },
    authContent: {
      position: 'relative',
      display: 'flex',
      flexGrow: 1,
      alignItems: 'stretch',
      flexShrink: 1,
      padding: '149px 112px 60px',
      [theme.breakpoints.down('sm')]: {
        padding: '40px 20px',
      },
    },
    authMain: {
      flexGrow: 1,
      flexShrink: 1,
      alignItems: 'stretch',

      [theme.breakpoints.down('sm')]: {
        padding: '40px 0',
        width: '100%',
      },
    },
    authFooter: {
      borderTop: `1px solid ${theme.custom.getDarkRgbaColor(0.1)}`,
      padding: '40px 112px',

      [theme.breakpoints.down('sm')]: {
        padding: '20px',
      },
    },
  };
}
