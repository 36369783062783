import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import cn from 'classnames';

import styles from './styles';
import { getDirname } from '../../../utils/parseQuery';

const useStyles = makeStyles(styles, { name: getDirname(__dirname) });

const rakEmail = 'support+rakid@rakwireless.com';

const SupportLink = ({ children, className }) => {
  const classes = useStyles();

  return (
    <a
      className={cn(classes.link, className)}
      href={`mailto:${rakEmail}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
};

SupportLink.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

SupportLink.defaultProps = {
  className: '',
};

export default SupportLink;
