import { useCallback, useMemo } from 'react';
import useLogout from '../../../../app/hooks/useLogout';

export default function useLogoutForm() {
  const { handleLogout } = useLogout();

  const onSubmit = useCallback(async () => {
    await handleLogout('success');
  }, [handleLogout]);

  return useMemo(() => ({ onSubmit }), [onSubmit]);
}
