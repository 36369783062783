import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import get from 'lodash.get';

import RoutePaths from '../../app/routes/routePaths';
import CONNECTION_RESPONSES from '../../pages/Auth/constants';
import AuthSelectors from '../../pages/Auth/selectors';
import API_REQ, { API_ACTIONS_CONSTANT } from '../apiActions';
import Auth from '../apiEndpoints/Auth';
import consentLogin from './consentLogin';
import updateAccessToken from './updateAccessToken';
import { clearCache, setCache } from './authCache';
import redirect from '../utils/redirect';
import cache from '../utils/cache';
import LOGOUT_STATUSES from '../../pages/Auth/Login/constants';

function* handleConnectionStatus({ connectionStatus, refreshToken }) {
  const isRAKPlatform = yield select(AuthSelectors.isRAKPlatform());
  const clientId = yield select(AuthSelectors.getClientId());

  switch (connectionStatus) {
    case CONNECTION_RESPONSES.exists_not_connected:
      // Connect manually for RAK ID portal
      if (isRAKPlatform) {
        return yield call(Auth.connect, { clientId });
      }
      return redirect(RoutePaths.Auth.Connect._());
    case CONNECTION_RESPONSES.exists_connected:
      return yield put(API_REQ.AUTH.CONSENT());
    case CONNECTION_RESPONSES.not_exists:
      return yield call(Auth.logout, { refreshToken });
    default:
      return redirect(RoutePaths.Auth.Login._());
  }
}

function* handleFinishLoginFLow({ connectionStatus, accessToken, refreshToken, idToken }) {
  setCache({ accessToken, refreshToken, idToken });

  yield call(handleConnectionStatus, { connectionStatus, refreshToken });
}

function* handleSuccessLoginSaga({ payload }) {
  // User has MFA enabled
  if (payload?.session) {
    return;
  }

  yield call(handleFinishLoginFLow, payload);
}

function* handleGetConnectionStatus({ payload: { result: connectionStatus } }) {
  const refreshToken = cache.getRefreshToken();

  yield call(handleConnectionStatus, { connectionStatus, refreshToken });
}

function* handleResendCodeError({ payload }) {
  const maxLimit = get(payload, 'response.code', null) === 'LimitExceededException';

  if (maxLimit) {
    yield put({
      type: API_ACTIONS_CONSTANT.AUTH.SET_NOTIFICATION_STATUS,
      payload: LOGOUT_STATUSES.limit.default,
    });
    redirect(RoutePaths.Auth.Login._());
  }
}

export default function* watchersSaga() {
  yield takeLatest(API_ACTIONS_CONSTANT.AUTH.TOKEN_EXPIRED, updateAccessToken);

  yield takeEvery(
    API_REQ.AUTH.REFRESH_TOKEN.SUCCESS,
    ({
      payload: { accessToken, idToken },
      meta: {
        input: { refreshToken },
      },
    }) => {
      setCache({ accessToken, refreshToken, idToken });
    },
  );

  yield takeEvery(
    [
      API_REQ.AUTH.LOGIN.SUCCESS,
      API_REQ.AUTH.MFA.LOGIN.SUCCESS,
      API_REQ.AUTH.MFA.RECOVERY_LOGIN.SUCCESS,
    ],
    handleSuccessLoginSaga,
  );

  yield takeEvery([API_REQ.AUTH.LOGOUT.SUCCESS, API_REQ.AUTH.REFRESH_TOKEN.ERROR], clearCache);

  yield takeEvery(API_REQ.AUTH.GET_CONNECTION_STATUS.SUCCESS, handleGetConnectionStatus);

  yield takeEvery(API_REQ.AUTH.GET_CONNECTION_STATUS.ERROR, () =>
    redirect(RoutePaths.Auth.Login._()),
  );

  yield takeEvery([API_REQ.AUTH.CONNECT.SUCCESS, API_ACTIONS_CONSTANT.AUTH.CONSENT], consentLogin);

  yield takeEvery(API_REQ.AUTH.FORGOT_PASSWORD.ERROR, handleResendCodeError);
}
