import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import { reduxForm } from 'redux-form';

import CustomButton from '../../../../UI/CustomButton';
import customButtonSizes from '../../../../UI/CustomButton/constants';

function LogoutForm({ handleSubmit, submitting }) {
  return (
    <form onSubmit={handleSubmit}>
      <CustomButton
        type="submit"
        variant="outlined"
        size={customButtonSizes.xSmall}
        loading={submitting}
        disabled={submitting}
      >
        <FormattedMessage id="common.header.headerSignOutBtn" />
      </CustomButton>
    </form>
  );
}

LogoutForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default compose(reduxForm({}))(LogoutForm);
