import React, { useRef } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import ButtonBase from '@material-ui/core/ButtonBase';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';
import cn from 'classnames';

import useTooltip from './hooks/useTooltip';
import styles from './styles';
import FIELD_TOOLTIP_PROP_TYPES from './types';
import useMobileScreenDetect from '../../../app/hooks/useMobileScreenDetect';
import { getDirname } from '../../../utils/parseQuery';

const useStyles = makeStyles(styles, { name: getDirname(__dirname) });

const FieldTooltip = ({
  contentClass,
  tooltipClass,
  arrowClass,
  buttonClass,
  tooltipId,
  children,
  disable,
}) => {
  const classes = useStyles();

  const iconRef = useRef(null);
  const { isOpen, setIsOpen } = useTooltip(iconRef);
  const { isMobile } = useMobileScreenDetect();

  const handleOpenTooltip = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const tooltipContent = (
    <div className={cn(classes.tooltipContent, contentClass)}>
      {typeof tooltipId === 'string' ? <FormattedMessage id={tooltipId} /> : tooltipId}
    </div>
  );

  return (
    <Tooltip
      classes={{
        tooltip: cn(classes.tooltip, tooltipClass),
        arrow: cn(classes.arrow, arrowClass),
      }}
      title={tooltipContent}
      {...(isMobile ? { open: isOpen } : {})}
      placement="top"
      arrow
      disableHoverListener={disable}
      disableFocusListener={disable}
      disableTouchListener={disable}
    >
      <ButtonBase className={buttonClass} ref={iconRef} disableRipple onClick={handleOpenTooltip}>
        {children}
      </ButtonBase>
    </Tooltip>
  );
};

FieldTooltip.propTypes = FIELD_TOOLTIP_PROP_TYPES.RULES;

FieldTooltip.defaultProps = FIELD_TOOLTIP_PROP_TYPES.DEFAULTS;

export default FieldTooltip;
