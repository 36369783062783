import { useEffect } from 'react';
import cache from '../../../../api/utils/cache';

const useWatchIsUserActiveState = ({ onChangeCallback, checkedValue, interval = 1000 }) => {
  useEffect(() => {
    const intervalId = setInterval(() => {
      const cookieValue = cache.getIsUserActive();
      if (typeof cookieValue !== 'undefined' && cookieValue !== checkedValue) {
        onChangeCallback(cookieValue);
      }
    }, interval);
    return () => clearInterval(intervalId);
  });
};

export default useWatchIsUserActiveState;
