import React from 'react';
import RoutePaths from '../../../app/routes/routePaths';
import { ReactComponent as PersonIcon } from '../../../assets/icons/sidebar/person.svg';
import { ReactComponent as LinkIcon } from '../../../assets/icons/sidebar/link.svg';
import { ReactComponent as LockIcon } from '../../../assets/icons/sidebar/lock.svg';

const menuItems = [
  {
    title: 'common.sidebar.profile',
    url: RoutePaths.UserProfile._(),
    icon: () => <PersonIcon />,
  },
  {
    title: 'common.sidebar.appsAndServices',
    url: RoutePaths.UserProfile.Products(),
    icon: () => <LinkIcon />,
  },
  {
    title: 'common.sidebar.security',
    url: RoutePaths.UserProfile.Security(),
    icon: () => <LockIcon />,
  },
];

export default menuItems;
