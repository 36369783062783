export default function styles(theme) {
  return {
    drawerOverlay: {
      backgroundColor: theme.custom.getDarkRgbaColor(0.4),
    },
    drawerWrap: {
      display: 'flex',
      width: '292px',
      height: '100%',
      flexDirection: 'column',
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      minHeight: '60px',
      padding: '0 20px',
    },
    closeIcon: {
      width: '14px',
      height: '13px',
      '& > line': {
        stroke: theme.custom.darkColor,
        strokeWidth: '2px',
      },
    },
    divider: {
      backgroundColor: theme.custom.darkColor,
      opacity: '0.1',
    },
    drawerSidebarWrap: {
      marginTop: '38px',

      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  };
}
