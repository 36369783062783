const connection = {
  connectTitle: 'Connecting RAK ID with ',
  connectSubTitle:
    'This is your first login to the {platform} product. By authorizing connection between RAK ID and {platform} you will share data below.',
  connectFooterHint: 'Do you want to authorize connection?',
  connectFormSubmitBtn: 'Yes, I want to authorize',
  connectFormCancelBtn: 'No, cancel',
  permissions: {
    connectPermissionsNameAndSurname: 'Your name and surname',
    connectPermissionsEmail: 'Email address',
    connectPermissionsPhoto: 'Profile photo',
    connectPermissionsDateAndTime: 'Date & time preferences',
    connectPermissionsLanguage: 'Language preference',
  }
}

export default connection
