export default function styles(theme) {
  return {
    popper: {
      width: '336px',
      zIndex: 2000,
    },
    divider: {
      background: theme.custom.darkColor,
      opacity: 0.1,
    },
    menuWrap: {
      background: theme.custom.whiteColor,
      boxShadow: '0px 4px 48px rgba(0, 0, 0, 0.1)',
      borderRadius: '16px',
      padding: 0,
    },
    header: {
      textAlign: 'right',
      marginTop: '4px',
    },
    headerIconWrap: {
      padding: '20px',
    },
    closeIcon: {
      width: '14px',
      height: '13px',
      '& > line': {
        stroke: theme.custom.darkColor,
        strokeWidth: '2px',
      },
    },
    menuItem: {
      paddingLeft: '28px',
      paddingRight: '28px',

      '&:hover': {
        background: 'transparent',
      },
    },
    identity: {
      paddingTop: 0,
      paddingBottom: '20px',
    },
    logout: {
      paddingTop: '20px',
      paddingBottom: '20px',
    },
  };
}
