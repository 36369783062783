import Cookies from 'universal-cookie';

const cookie = new Cookies();

const getDomainRoot = () => window.location.hostname.split('.').at(-2);
const getDomainTopLeveL = () => window.location.hostname.split('.').at(-1);

const options = {
  path: '/',
  domain: `.${getDomainRoot()}.${getDomainTopLeveL()}`,
  sameSite: 'lax',
  secure: true,
};

const setCookie = (key, value, customExpireDate) => {
  if (customExpireDate) {
    cookie.set(key, value, { ...options, expires: customExpireDate });
    return;
  }
  cookie.set(key, value, options);
};

const getCookie = (key) => cookie.get(key, options);

export { setCookie, getCookie };
