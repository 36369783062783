import customTheme from '../../../app/theme/configMaterialTheme';

export default function styles(theme) {
  return {
    root: {
      width: '90%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      '& .Toastify__toast': {
        color: customTheme.custom.whiteColor || theme.custom.whiteColor,
        boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.06)',
        borderRadius: '4px',
        height: '60px',
        [theme.breakpoints.down('sm')]: {
          height: 'auto',
        },
      },
      '& .Toastify__close-button': {
        opacity: 1,
        alignSelf: 'center',
        marginRight: '7px',
        '&--warning': {
          color: customTheme.custom.darkColor || theme.custom.darkColor,
        },
        [theme.breakpoints.down('sm')]: {
          alignSelf: 'baseline',
          marginTop: '5px',
        },
      },
      '& .Toastify__progress-bar': {
        opacity: '0.4 !important',
      },
      '& .Toastify__toast--error': {
        background: '#F13456',
      },
      '& .Toastify__toast--success': {
        background: '#31B468',
      },
      '& .Toastify__toast--info': {
        background: '#006AC6',
      },
      '& .Toastify__toast--warning': {
        background: '#FCCC6F',
      },
    },
    title: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '16px',
      whiteSpace: 'nowrap',
      color: ({ data: { type = '' } = {} } = {}) =>
        type === 'warning'
          ? customTheme.custom.darkColor || theme.custom.darkColor
          : customTheme.custom.whiteColor || theme.custom.whiteColor,

      '& > span': {
        textTransform: 'capitalize',
      },
    },
    subTitle: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '16px',
      color: ({ data: { type = '' } = {} } = {}) =>
        type === 'warning'
          ? customTheme.custom.textColor || theme.custom.textColor
          : customTheme.custom.whiteColor || theme.custom.whiteColor,
    },
    wrap: {
      display: 'flex',
      marginLeft: '20px',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        alignItems: 'unset',
        flexDirection: 'column',
      },
    },
    header: {
      width: '170px',
      display: 'flex',
      alignItems: 'center',
      marginBottom: '5px',
    },
    content: {
      width: '100%',
      margin: '0 24px',

      [theme.breakpoints.down('sm')]: {
        width: 'auto',
        margin: '0 0 0 32px',
      },
    },
    icon: {
      marginRight: '16px',
      color: ({ data: { type = '' } = {} } = {}) =>
        type === 'warning'
          ? customTheme.custom.darkColor || theme.custom.darkColor
          : customTheme.custom.whiteColor || theme.custom.whiteColor,
    },
  };
}
