import { call, put } from 'redux-saga/effects';
import history from '../../app/redux/configureBrowserHistory';
import RoutePaths from '../../app/routes/routePaths';
import getFormattedProductName from '../../pages/Products/utils';
import parseQuery from '../../utils/parseQuery';
import API_REQ from '../apiActions';
import Auth from '../apiEndpoints/Auth';
import redirect from '../utils/redirect';

const { REACT_APP_RAK_PORTAL_CLIENT_ID, REACT_APP_RAK_PORTAL_PLATFORM, REACT_APP_PLATFORMS_LIST } =
  process.env;

function* handleAPISession() {
  const { ok, result } = yield call(Auth.checkSession);

  // Create session manually
  if (!ok || !result?.result) {
    yield call(Auth.createSession, {
      client_id: REACT_APP_RAK_PORTAL_CLIENT_ID,
      redirect_uri: window.origin,
      response_type: 'token',
    });
  }
}

export default function* handleURLQuery() {
  const {
    client_id: clientId = REACT_APP_RAK_PORTAL_CLIENT_ID,
    platform = REACT_APP_RAK_PORTAL_PLATFORM,
    hint = null,
  } = parseQuery(history.location.search);

  if (!REACT_APP_PLATFORMS_LIST.includes(platform?.toLowerCase())) {
    history.replace({ pathname: RoutePaths.Auth.Login._() });
    return;
  }

  yield call(handleAPISession);

  yield put(
    API_REQ.AUTH.SET_SESSION({
      clientId,
      platform: getFormattedProductName(platform),
    }),
  );

  if (hint) {
    redirect(RoutePaths.Auth.Registration._());
  }
}
