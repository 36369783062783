import { makeStyles } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';

import {
  userProfileDefaultPropTypes,
  userProfilePropTypes,
} from '../../../../../pages/UserProfile/types';
import Avatar from '../../../../UI/Avatar';
import FieldTooltip from '../../../../formElements/FieldTooltip';
import styles from './styles';
import { getDirname } from '../../../../../utils/parseQuery';

const useStyles = makeStyles(styles, { name: getDirname(__dirname) });

function ProfileAvatar({ userProfile }) {
  const classes = useStyles();

  const nameRef = useRef(null);
  const [isDisabledTooltip, setIsDisabledTooltip] = useState(false);

  useEffect(() => {
    setIsDisabledTooltip(nameRef.current?.offsetWidth >= nameRef.current?.scrollWidth);
  }, [nameRef]);

  return (
    <div className={classes.root}>
      <div className={classes.photo}>
        <Avatar
          photo={userProfile.profilePhoto}
          name={userProfile.name || userProfile.email}
          size="large"
        />
      </div>
      <FieldTooltip
        tooltipClass={classes.tooltip}
        buttonClass={classes.tooltipButton}
        tooltipId={<>{userProfile.name || userProfile.email}</>}
        disable={isDisabledTooltip}
      >
        <div className={classes.name} ref={nameRef}>
          {userProfile.name || userProfile.email}
        </div>
      </FieldTooltip>
    </div>
  );
}

ProfileAvatar.propTypes = {
  userProfile: userProfilePropTypes,
};

ProfileAvatar.defaultProps = {
  userProfile: userProfileDefaultPropTypes,
};

export default ProfileAvatar;
