const LOGOUT_STATUSES = {
  success: 'success',
  expired: 'expired',
  terminate: 'terminate',
  limit: {
    password: 'limitValidate',
    mfa: {
      password: 'limitMFAPassword',
      code: 'limitMFACode',
    },
    recoveryCodes: {
      code: 'limitRecoveryCode',
    },
    default: 'limit',
  },
};

export const RAKWIRELESS_PASSWORD_POLICY = 'https://redirect.kritto.net/wisdm4410168190103';

export default LOGOUT_STATUSES;
