export default function styles(theme) {
  return {
    root: {
      borderRadius: '36px',
      display: 'flex',
      alignItems: 'center',
    },
    containedPrimary: {
      background: theme.palette.primary.main,
      color: theme.custom.whiteColor,

      '&:hover': {
        boxShadow: `0px 0px 5px 5px ${theme.palette.primary.light}`,
        transition: 'box-shadow 0.3s ease-in-out',
      },
      '&:active': {
        boxShadow: `0px 0px 5px 5px ${theme.palette.primary.dark}`,
      },
      '&:disabled': {
        opacity: '0.3',
      },
    },
    outlinedPrimary: {
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,

      '&:hover': {
        boxShadow: `0px 0px 5px 5px ${theme.palette.primary.light}`,
        transition: 'box-shadow 0.3s ease-in-out',
      },
      '&:active': {
        boxShadow: `0px 0px 5px 5px ${theme.palette.primary.dark}`,
      },
      '&:disabled': {
        opacity: '0.3',
      },
    },
    containedSecondary: {
      background: theme.custom.errorColor,
      color: theme.custom.whiteColor,

      '&:hover': {
        boxShadow: `0px 0px 5px 5px ${theme.custom.errorLight}`,
        transition: 'box-shadow 0.3s ease-in-out',
      },
      '&:active': {
        boxShadow: `0px 0px 5px 5px ${theme.custom.errorDark}`,
      },
      '&:disabled': {
        opacity: '0.3',
      },
    },
    outlinedSecondary: {
      color: theme.custom.errorColor,
      border: `1px solid ${theme.custom.errorColor}`,

      '&:hover': {
        boxShadow: `0px 0px 5px 5px ${theme.custom.errorLight}`,
        transition: 'box-shadow 0.3s ease-in-out',
      },
      '&:active': {
        boxShadow: `0px 0px 5px 5px ${theme.custom.errorDark}`,
      },
      '&:disabled': {
        opacity: '0.3',
      },
    },
    large: {
      height: '60px',
      fontSize: '14px',
      lineHeight: '60px',
      padding: '0px 60px',
    },
    medium: {
      height: '48px',
      fontSize: '14px',
      lineHeight: '48px',
      padding: '0px 50px',
    },
    small: {
      height: '40px',
      fontSize: '14px',
      lineHeight: '40px',
      padding: '0px 32px',
    },
    xSmall: {
      height: '32px',
      fontSize: '12px',
      lineHeight: '32px',
      padding: '0px 28px',
    },
    loading: {
      pointerEvents: 'none',
    },
    loaderContainedPrimary: {
      color: theme.custom.whiteColor,
    },
    loaderContainedSecondary: {
      color: theme.custom.whiteColor,
    },
    loaderOutlinedPrimary: {
      color: theme.palette.primary.main,
    },
    loaderOutlinedSecondary: {
      color: theme.custom.errorColor,
    },
    fullWidth: {
      width: '100%',
    },
  };
}
