import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, makeStyles } from '@material-ui/core';
import cn from 'classnames';

import styles from './styles';
import { getDirname } from '../../../utils/parseQuery';

const useStyles = makeStyles(styles, { name: getDirname(__dirname) });

const Loader = ({ isAbsoluteCentered, ...props }) => {
  const classes = useStyles();

  return (
    <div className={cn(classes.root, { [classes.absoluteCenteredRoot]: isAbsoluteCentered })}>
      <CircularProgress {...props} />
    </div>
  );
};

Loader.propTypes = {
  isAbsoluteCentered: PropTypes.bool,
};

Loader.defaultProps = {
  isAbsoluteCentered: false,
};

export default Loader;
