export default function styles(theme) {
  return {
    input: {
      width: '100%',
      '-webkit-box-shadow': 'inset 0 0 0 30px #fff',
      '-webkit-text-fill-color': theme.custom.getDarkRgbaColor(0.8),
      color: `${theme.custom.getDarkRgbaColor(0.8)} !important`,
      fontFamily: 'Inter',
      fontSize: '14px',
      lineHeight: '20px',
    },
    inputWrap: {
      width: '100%',
      height: '48px',
      background: 'transparent',
      border: ({
        meta: { error = false, touched = false, active = false } = {},
      } = {}) => {
        if (error && touched) {
          return `1px solid ${theme.custom.errorColor}`;
        }
        if (active) {
          return '1px solid rgba(0, 0, 0, 0.4)';
        }
        return '1px solid rgba(0, 0, 0, 0.12)';
      },
      transition: 'all .2s',
      boxSizing: 'border-box',
      borderRadius: '4px',
      display: 'flex',
      alignItems: 'center',
      padding: '16px',
      position: 'relative',
      '&:hover': {
        border: ({ meta: { error = false, touched = false } = {} } = {}) =>
          error && touched
            ? `1px solid ${theme.custom.errorColor}`
            : '1px solid rgba(0, 0, 0, 0.4)',
      },

      [theme.breakpoints.down('sm')]: {
        height: '40px',
      },
    },
    multiline: {
      minHeight: '100px',
      height: 'auto',
      padding: '10px',
      '& .MuiInputBase-multiline': {
        padding: 0,
      },
    },
    disabled: {
      color: theme.custom.darkColor,
      opacity: 0.4,
      pointerEvents: 'none',
    },
    iconWrap: {
      display: 'flex',
    },
  };
}
