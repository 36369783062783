import get from 'lodash.get';
import { call, put, race, select, take } from 'redux-saga/effects';

import createAsyncActions from '../utils/createAsyncActions';
import xhrSaga from './xhrSaga';
import API_REQ, { API_ACTIONS_CONSTANT } from '../apiActions';
import history from '../../app/redux/configureBrowserHistory';
import RoutePaths from '../../app/routes/routePaths';
import AuthSelectors from '../../pages/Auth/selectors';
import cache from '../utils/cache';
import LOGOUT_STATUSES from '../../pages/Auth/Login/constants';

const fallbackReqMeta = {
  muteError: true,
  maxRetries: 1,
  rawResult: false,
  authRequired: true,
  title: 'FALLBACK',
  ignorePendingExchange: false,
};

export default function authSagaCreator(actionTypes, reqConfigCreator, baseReqMeta) {
  const actions = createAsyncActions(actionTypes);

  return function* apiRequestSaga(input, customReqMeta) {
    const meta = {
      input,
      ...fallbackReqMeta,
      ...(baseReqMeta || {}),
      ...(customReqMeta || {}),
    };

    yield put(actions.start(input, meta));

    const isExchangeTokenPending = yield select(AuthSelectors.getIsExchangeTokenPending());

    if (isExchangeTokenPending && !meta.ignorePendingExchange) {
      const { exchangeError } = yield race({
        exchangeSuccess: take(API_REQ.AUTH.REFRESH_TOKEN.SUCCESS),
        exchangeError: take(API_REQ.AUTH.REFRESH_TOKEN.ERROR),
      });

      if (exchangeError) {
        yield put(actions.error(exchangeError.payload, meta));
        return exchangeError.payload;
      }
    }

    const reqConfig = reqConfigCreator(input);

    const { response, parallelAuthFailed } = yield race({
      response: call(() => xhrSaga(reqConfig, meta)),
      parallelAuthFailed: take(API_ACTIONS_CONSTANT.AUTH.TOKEN_EXPIRED),
    });

    const reqAuthFailed = response && response.status === 401;
    const isTerminateProfile = response && response.status === 409;

    if (isTerminateProfile) {
      return yield put({
        type: API_REQ.AUTH.LOGOUT.SUCCESS,
        meta: { logoutStatus: LOGOUT_STATUSES.terminate },
      });
    }

    const noSession = get(response, 'data.code', null) === 'NoSessionCode';

    if (noSession) {
      yield put(API_REQ.AUTH.SET_SESSION({ clientId: '', platform: '' }));
      history.push(RoutePaths.Auth.AuthError._());
      return {
        ok: false,
        result: null,
      };
    }

    if (parallelAuthFailed || reqAuthFailed) {
      const refreshToken = cache.getRefreshToken();
      const suppressTokenExpired = yield select(AuthSelectors.getIsExchangeTokenPending());

      if (reqAuthFailed && !suppressTokenExpired) {
        yield put(API_REQ.AUTH.TOKEN_EXPIRED(refreshToken, meta));
      }

      if (meta.maxRetries > 0 && refreshToken) {
        return yield call(apiRequestSaga, input, {
          ...meta,
          maxRetries: meta.maxRetries - 1,
        });
      }
    }

    const finalAction =
      response.status >= 400 || !response.status ? actions.error : actions.success;

    let finalPayload;

    if (meta.rawResult) {
      finalPayload = response;
    } else {
      finalPayload =
        finalAction === actions.error
          ? {
              ...get(response, 'data', {
                errors: [{ message: response.statusText }],
              }),
              status: response.status,
            }
          : get(response, 'data', null);
    }

    yield put(finalAction(finalPayload, meta));
    return {
      ok: finalAction === actions.success,
      result: finalPayload,
    };
  };
}
