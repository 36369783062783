import React from 'react';
import PropTypes from 'prop-types';
import { MuiThemeProvider } from '@material-ui/core';

import theme from '../theme/configMaterialTheme';

const MUIThemeProvider = ({ children }) => (
  <MuiThemeProvider theme={theme}>
    {children}
  </MuiThemeProvider>
);

MUIThemeProvider.propTypes = {
  children: PropTypes.node,
};

MUIThemeProvider.defaultProps = {
  children: <div />,
};

export default MUIThemeProvider;
