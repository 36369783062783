export default function styles() {
  return {
    root: {
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      height: '100%',
    },
    absoluteCenteredRoot: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    }
  };
}
