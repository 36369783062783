export const HTTP_METHODS = {
  POST: 'POST',
  PUT: 'PUT',
  GET: 'GET',
  DELETE: 'DELETE',
};

export const ApiPaths = {
  Api: {
    Version: '/version',
  },
  Auth: {
    CreateSession: '/auth2/auth',
    CheckSession: '/auth2/session',
    Login: '/auth2/auth',
    MFA: {
      Login: '/auth2/confirm-auth',
      RecoveryLogin: '/auth2/recover-auth',
    },
    Logout: '/auth2/logout',
    VerifyEmail: '/auth2/confirm-signup',
    ResendCode: '/auth2/resend-confirmation',
    Consent: 'auth2/consent',
    ConsentLogged: 'auth2/consent-logged',
    Connect: '/auth2/connection',
    GetConnectionStatus: '/auth2/connection-status',
    GetClientSharingData: '/auth2/client',
    Token: '/auth2/token',
    SignUp: '/auth2/signup',
    ForgotPassword: '/auth2/forgot-password',
    ConfirmPassword: '/auth2/confirm-password',
    ValidatePassword: '/auth2/validate-password',
  },
  Profile: {
    Fetch: '/users2/me',
    Update: '/users2/me',
    UpdatePhoto: '/users2/me/profile-photo',
    Disconnect: '/users2/me/connection',
    Terminate: '/users2/me',
    Export: '/users2/me/pdf',
  },
  MFA: {
    Generate: '/auth2/authenticator',
    Create: '/auth2/mfa',
    Delete: '/auth2/mfa',
    RescueCode: {
      Fetch: '/auth2/rescue-codes',
      Generate: '/auth2/rescue-codes',
      Download: '/auth2/rescue-codes/txt',
    },
  },
};
