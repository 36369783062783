import React from 'react';
import { ButtonBase, CircularProgress, capitalize, makeStyles } from '@material-ui/core';
import cn from 'classnames';

import styles from './styles';
import CUSTOM_BUTTON_PROP_TYPES from './types';
import { getDirname } from '../../../utils/parseQuery';

const useStyles = makeStyles(styles, { name: getDirname(__dirname) });

function CustomButton({
  additionalClassName,
  size,
  variant,
  color,
  disabled,
  loading,
  fullWidth,
  type,
  onClick,
  children,
  ...props
}) {
  const classes = useStyles();

  return (
    <ButtonBase
      className={cn(
        classes.root,
        classes[variant],
        classes[`${variant}${capitalize(color)}`],
        classes[size],
        {
          [classes.loading]: loading,
          [classes.fullWidth]: fullWidth,
        },
        additionalClassName,
      )}
      type={type}
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      {loading ? (
        <CircularProgress
          className={classes[`loader${capitalize(variant)}${capitalize(color)}`]}
          size={24}
        />
      ) : (
        children
      )}
    </ButtonBase>
  );
}

CustomButton.propTypes = CUSTOM_BUTTON_PROP_TYPES.RULES;

CustomButton.defaultProps = CUSTOM_BUTTON_PROP_TYPES.DEFAULTS;

export default CustomButton;
