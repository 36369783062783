import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useIdleTimer } from 'react-idle-timer';

import CustomModal from '../CustomModal';
import { actionDelay, logoutDelay, timeBeforeLogout } from './constants';
import { ReactComponent as ShieldIcon } from '../../../assets/icons/other/shieldIcon.svg';
import useLogout from '../../../app/hooks/useLogout';
import { getDirname } from '../../../utils/parseQuery';
import cache from '../../../api/utils/cache';
import useWatchIsUserActiveState from './hooks/useWatchIsUserActiveState';
import LOGOUT_STATUSES from '../../../pages/Auth/Login/constants';
import styles from './styles';

const useStyles = makeStyles(styles, { name: getDirname(__dirname) });

const AutoLogoutModal = () => {
  const classes = useStyles();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [timerId, setTimerId] = useState(undefined);
  const [isUserActive, setIsUserActive] = useState(null);
  const { handleLogout } = useLogout();

  const performLogout = useCallback(async () => {
    await handleLogout(LOGOUT_STATUSES.expired);
  }, [handleLogout]);

  const handleIdle = () => {
    const timer = setTimeout(performLogout, logoutDelay);
    setTimerId(timer);
    setIsOpenModal(true);
  };

  const handleUserActivity = () => {
    const value = isOpenModal ? isUserActive : new Date().getTime();
    cache.setIsUserActive(value);

    if (!isUserActive) {
      setIsUserActive(value);
    }
  };

  const { reset } = useIdleTimer({
    timeout: timeBeforeLogout,
    onIdle: handleIdle,
    onActive: handleUserActivity,
    onAction: handleUserActivity,
    debounce: actionDelay,
    stopOnIdle: true,
  });

  const handleContinueSession = () => {
    reset();
    clearTimeout(timerId);
    setIsOpenModal(false);
  };

  const handleSetIsUserActive = (value) => {
    setIsUserActive(value);
    if (isOpenModal) {
      handleContinueSession();
    } else {
      reset();
    }
  };

  useWatchIsUserActiveState({
    onChangeCallback: handleSetIsUserActive,
    checkedValue: isUserActive,
    interval: actionDelay,
  });

  return (
    <CustomModal
      handleClose={handleContinueSession}
      isOpen={isOpenModal}
      cancelText="common.autoLogoutModal.autoLogoutModalBtn"
      titleId="common.autoLogoutModal.autoLogoutModalTitle"
      onlyClose
    >
      <div className={classes.noticeWrap}>
        <div className={classes.noticeIcon}>
          <ShieldIcon />
        </div>

        <div className={classes.noticeText}>
          <FormattedMessage id="common.autoLogoutModal.autoLogoutModalText" />
        </div>
      </div>
    </CustomModal>
  );
};

export default AutoLogoutModal;
