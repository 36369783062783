import { makeStyles } from '@material-ui/core';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import usePush from '../../../../../app/hooks/usePush';
import RoutePaths from '../../../../../app/routes/routePaths';
import CustomButton from '../../../../UI/CustomButton';
import customButtonSizes from '../../../../UI/CustomButton/constants';
import styles from './styles';
import { getDirname } from '../../../../../utils/parseQuery';
import AuthSelectors from '../../../../../pages/Auth/selectors';
import useProductType from '../../../../../app/hooks/useProductType';
import CONNECTION_RESPONSES from '../../../../../pages/Auth/constants';

const useStyles = makeStyles(styles, { name: getDirname(__dirname) });

const SinglePageNavigation = () => {
  const classes = useStyles();
  const { search } = useLocation();
  const push = usePush();
  const isSignedIn = useSelector(AuthSelectors.getIsSigned());
  const connectionStatus = useSelector(AuthSelectors.getConnectionStatus());
  const { isExternalProduct, isInternalProduct } = useProductType();

  const handleLoginRedirect = useCallback(() => {
    let pathname = RoutePaths.Auth.Login._();

    if (isSignedIn && isExternalProduct) {
      pathname = RoutePaths.Auth.Login.Identity._();
    }
    if (isInternalProduct && connectionStatus === CONNECTION_RESPONSES.exists_not_connected) {
      pathname = RoutePaths.Auth.Connect._();
    }

    return push(pathname);
  }, [connectionStatus, isExternalProduct, isInternalProduct, isSignedIn, push]);

  return (
    <div className={classes.navigationWrap}>
      <Link className={classes.link} to={{ pathname: RoutePaths.Auth.Registration._(), search }}>
        <FormattedMessage id="about.aboutPageHeaderSignUp" />
      </Link>
      <CustomButton
        additionalClassName={classes.button}
        size={customButtonSizes.small}
        onClick={handleLoginRedirect}
      >
        <FormattedMessage id="about.aboutPageHeaderSignIn" />
      </CustomButton>
    </div>
  );
};

export default SinglePageNavigation;
