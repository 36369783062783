import React from 'react';
import cn from 'classnames';
import { Avatar as AvatarComponent, makeStyles } from '@material-ui/core';

import shortName from './utils';
import styles from './styles';
import AVATAR_PROP_TYPES from './types';
import { getDirname } from '../../../utils/parseQuery';

const useStyles = makeStyles(styles, { name: getDirname(__dirname) });

function Avatar({ photo, name, size, withBorder }) {
  const classes = useStyles({ size });

  return (
    <div className={cn(classes.wrap, { [classes.withBorder]: withBorder })}>
      <AvatarComponent className={cn(classes.avatar, classes[size])} src={photo}>
        {shortName(name)}
      </AvatarComponent>
    </div>
  );
}

Avatar.propTypes = AVATAR_PROP_TYPES.RULES;

Avatar.defaultProps = AVATAR_PROP_TYPES.DEFAULTS;

export default Avatar;
