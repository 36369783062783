import { useCallback, useState } from 'react';

const useModal = () => {
  const [open, setOpen] = useState(false);

  const handleCloseModal = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOpenModal = useCallback(() => {
    setOpen(true);
  }, []);

  return {
    open,
    handleCloseModal,
    handleOpenModal,
  };
};

export default useModal;
