import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { connectRouter } from 'connected-react-router';

import apiReducer from '../../api/reducer';
import userProfile from '../../pages/UserProfile/reducer';
import history from './configureBrowserHistory';
import authReducer from '../../pages/Auth/reducer';

const rootReducer = combineReducers({
  form: formReducer,
  router: connectRouter(history),
  api: apiReducer,
  auth: authReducer,
  userProfile,
});

export default rootReducer;
