const security = {
  title: 'Two-factor authentication',
  subTitle: `Two-factor authentication adds an extra layer of security to verify it's actually you trying to sign in. Protect your RAK ID by turning on 2FA.`,
  optionLabel: 'Two-factor auth.',
  optionOn: 'On',
  optionOff: 'Off',
  empty: `Before switching 'On' 2FA, you should have an authentication app supporting TOTP (Time-based one-time password).`,
  dividerText: 'or',
  requestRecoveryCode: 'Request new recovery codes',
  activatedDateMessage: 'Two-factor authentication is ON since {date}.',
  activateModal: {
    title: 'Activate 2FA',
    scanText: 'Scan the QR code in your authenticator app',
    copyText: 'Copy and paste the text code in your authenticator app',
    copyBtn: 'Copy',
    copied: 'Copied',
    failed: 'Something went wrong.',
  },
  confirmModal: {
    title: 'Activate 2FA',
    codeText: 'Enter the 6-digit code from your authenticator app',
    codeLabel: 'GENERATED CODE',
  },
  disableModal: {
    title: 'Turning off 2FA',
    text: 'Turning off Two-factor authentication will remove the extra layer of security and you’ll only use your RAK ID and password to sign in. Your device supporting 2FA will be removed from RAK ID.{br}{br}To confirm operation, enter your password and the 6-digit code from your authenticator app.',
    submitBtnText: 'Yes, I want to turn off',
    success: '2FA deactivated',
  },
  rescueCodeModal: {
    activate: {
      title: 'Download recovery codes',
      text: 'Keep these recovery codes somewhere safe but accessible. They can be used if you cannot receive 2FA codes.',
      success: '2FA activated',
    },
    generate: {
      title: 'Request new recovery codes',
      text: 'Your previous recovery codes are invalid. Keep these recovery codes somewhere safe but accessible. They can be used if you cannot receive 2FA codes.',
    },
    text: 'To create a new set of recovery codes, enter your password and the 6-digit code from your authenticator app.',
    submitBtnText: 'Continue',
    downloadBtnText: 'Download a copy',
    filenameTemplate: 'rescuecodes{date}.txt',
  },
  errors: {
    password: 'Invalid password. Try again.',
    mfaCode: 'Invalid 2FA code. Try again.',
    recoveryCode: 'Invalid recovery code. Try again.',
    rateLimit: {
      password: 'Too many failed password attempts. Try signing in again.',
      mfaCode: 'Too many failed 2FA code attempts. Try signing in again.',
      recoveryCode: 'Too many failed recovery code attempts. Try signing in again.',
    },
  },
  fields: {
    password: 'Write your password in the box below',
    code: 'and enter code from authenticator app',
  },
};

export default security;
