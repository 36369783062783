import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';

import messages from '../intl';

function LocalesProvider({ children }) {
  return (
    <IntlProvider locale="en" messages={messages.en}>
      {children}
    </IntlProvider>
  );
}

LocalesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LocalesProvider;
