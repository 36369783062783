import React, { lazy } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import RoutePaths from './routePaths';
import AuthRoute from './AuthRoute';
import UnauthRoute from './UnauthRoute';
import SinglePageRoute from './SinglePageRoute';
import ConnectZendesk from '../../pages/ConnectZendesk';
import ForceLogout from '../../pages/ForceLogout';

import withInitialLoader from '../hocs/withInitialLoading';

const Login = lazy(() => import('../../pages/Auth/Login'));
const Identity = lazy(() => import('../../pages/Auth/Login/Identity'));
const VerifyEmail = lazy(() => import('../../pages/Auth/VerifyEmail'));
const Connect = lazy(() => import('../../pages/Auth/Connect'));
const AuthError = lazy(() => import('../../pages/Auth/AuthError'));
const Registration = lazy(() => import('../../pages/Auth/Registration'));
const ForgotPassword = lazy(() => import('../../pages/Auth/ForgotPassword'));
const ConfirmPassword = lazy(() => import('../../pages/Auth/ConfirmPassword'));
const ProfilePage = lazy(() => import('../../pages/UserProfile'));
const Products = lazy(() => import('../../pages/Products'));
const Security = lazy(() => import('../../pages/Security'));
const About = lazy(() => import('../../pages/About'));

function AppRoutes() {
  const { pathname, search, hash } = useLocation();
  return (
    <Switch>
      <Redirect from="/:url*(/+)" to={{ pathname: pathname.slice(0, -1), search }} />

      <UnauthRoute exact path={RoutePaths.Auth.Login._()} component={Login} />

      <UnauthRoute exact path={RoutePaths.Auth.Login.Identity._()} component={Identity} />

      <UnauthRoute exact path={RoutePaths.Auth.VerifyEmail._()} component={VerifyEmail} />

      <UnauthRoute exact path={RoutePaths.Auth.Connect._()} component={Connect} />

      <UnauthRoute exact path={RoutePaths.Auth.Registration._()} component={Registration} />

      <UnauthRoute exact path={RoutePaths.Auth.ForgotPassword._()} component={ForgotPassword} />

      <UnauthRoute exact path={RoutePaths.Auth.ConfirmPassword._()} component={ConfirmPassword} />

      <UnauthRoute exact path={RoutePaths.Auth.AuthError._()} component={AuthError} />

      <AuthRoute exact path={RoutePaths.UserProfile._()} component={ProfilePage} />

      <AuthRoute exact path={RoutePaths.UserProfile.Products()} component={Products} />

      <AuthRoute exact path={RoutePaths.UserProfile.Security()} component={Security} />

      <SinglePageRoute exact path={RoutePaths.Single.About()} component={About} />

      <Route exact path={RoutePaths.Single.ConnectZendesk()} component={ConnectZendesk} />

      <Route exact path={RoutePaths.Single.ForceLogout()} component={ForceLogout} />

      <Redirect to={{ pathname: RoutePaths.Auth.Login._(), search, hash }} />
    </Switch>
  );
}

export default withInitialLoader(AppRoutes);
