import { linkMixin, textMixin, textSizes } from '../../theme/mixins';

export default function styles(theme) {
  return {
    root: {
      width: '100%',
      minHeight: '100vh',
      backgroundColor: theme.custom.backgroundColor,

      [theme.breakpoints.down('sm')]: {
        backgroundColor: 'transparent',
      },
    },
    layout: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'stretch',
      width: '100%',
      maxWidth: '456px',
      minHeight: '100vh',
      margin: '0 auto',
      zIndex: 1,
    },
    footer: {
      margin: '28px',
      textAlign: 'center',

      ...textMixin({
        size: textSizes.small,
        color: theme.custom.textColor,
        opacity: 0.6,
      }),
    },
    policiesWrap: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '28px',
    },
    separator: {
      margin: '0 8px',
    },
    footerLink: {
      ...linkMixin(),
      fontSize: '11px',
      lineHeight: '16px',
    },
  };
}
