import React from 'react';
import { Route, withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import SinglePageLayout from '../layouts/SinglePageLayout';
import RouteWrapper from './RouteWrapper';

function SinglePageRoute({ ...props }) {
  return (
    <SinglePageLayout>
      <RouteWrapper>
        <Route {...props} />
      </RouteWrapper>
    </SinglePageLayout>
  );
}

export default compose(withRouter)(SinglePageRoute);
