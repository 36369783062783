import apiReqSagaCreator from '../apiReqSagaCreator';
import API_REQ from '../apiActions';
import { ApiPaths, HTTP_METHODS } from '../apiConstants';

const Api = {
  version: apiReqSagaCreator(
    API_REQ.API.VERSION,
    () => ({
      url: ApiPaths.Api.Version,
      method: HTTP_METHODS.GET,
    }),
    {
      authRequired: false,
    },
  ),
};

export default Api;
