import { textMixin, textSizes } from '../../../../../app/theme/mixins';

export default function styles(theme) {
  return {
    root: {
      textAlign: 'center',
    },
    photo: {
      marginBottom: '8px',
    },
    name: {
      ...textMixin({
        size: textSizes.medium,
        color: theme.custom.darkColor,
        opacity: 1,
      }),
      fontWeight: 600,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    tooltip: {
      height: 'auto',
      width: 'auto',
    },
    tooltipButton: {
      width: '100%',
    },
  };
}
