const disconnect = {
  disconnectProfileModalTitle: 'Remove {name}?',
  disconnectProfileInternalModalBtn: 'Remove {name}',
  disconnectProfileModalNoticeInternal:
    "Are you sure? You'll no longer be able to use RAK ID to gain access to {name}, and all your related data and assets will be deleted permanently from our servers.",
  disconnectProfileModalNoticeExternal: `Are you sure? {name} will no longer have access to RAK ID and chances are you might not be able to access or use the {name} account either.`,
  disconnectProfileModalSuccess:
    'You have removed {name} from your RAK ID. You have deleted all related data.',
  disconnectProfileModalWarning:
    'You cannot remove {name} from here. Sign in to {name} and remove RAK ID.',
  disconnectProfileModalWarningBtn: 'Go to {name}',
};

export default disconnect;
