import { createSelector } from 'reselect';

const { REACT_APP_RAK_PORTAL_PLATFORM } = process.env;

const authState = (state) => state.auth;

const AuthSelectors = {
  getAllowedProperties() {
    return createSelector(authState, ({ allowedProperties }) => allowedProperties);
  },
  getClientId() {
    return createSelector(authState, ({ clientId }) => clientId);
  },
  getPlatform() {
    return createSelector(authState, ({ platform }) => platform);
  },
  isRAKPlatform() {
    return createSelector(authState, ({ platform }) => platform === REACT_APP_RAK_PORTAL_PLATFORM);
  },
  isWisToolPlatform() {
    return createSelector(authState, ({ platform }) => platform === 'WisToolBox');
  },
  getIsSigned() {
    return createSelector(authState, ({ signedIn }) => signedIn);
  },
  getIsExchangeTokenPending() {
    return createSelector(authState, ({ exchangeTokenPending }) => exchangeTokenPending);
  },
  getInitialLoading() {
    return createSelector(authState, ({ cachedAuthTokenVerified }) => !cachedAuthTokenVerified);
  },
  getConnectionStatus() {
    return createSelector(authState, ({ connectionStatus }) => connectionStatus);
  },
  getLogoutStatus() {
    return createSelector(authState, ({ logoutStatus }) => logoutStatus);
  },
  getNotificationStatus() {
    return createSelector(authState, ({ notificationStatus }) => notificationStatus);
  },
  getMFASignInSession() {
    return createSelector(authState, ({ MFASignInSession }) => MFASignInSession);
  },
};

export default AuthSelectors;
