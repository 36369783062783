import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { ApiPaths } from '../../api/apiConstants';
import parseQuery from '../../utils/parseQuery';

const { REACT_APP_ZENDESK_CLIENT_ID, REACT_APP_API_URL } = process.env;

const ConnectZendesk = () => {
  const { search } = useLocation();
  // eslint-disable-next-line camelcase
  const { return_to } = parseQuery(search);

  useEffect(() => {
    const url = new URL(ApiPaths.Auth.CreateSession, REACT_APP_API_URL);
    url.searchParams.set('client_id', REACT_APP_ZENDESK_CLIENT_ID);
    url.searchParams.set('returnTo', return_to);
    url.searchParams.set('response_type', 'token');
    window.location.assign(url.toString());
    // eslint-disable-next-line camelcase
  }, [return_to]);

  return <div />;
};

export default ConnectZendesk;
