import theme from './configMaterialTheme';

export const textSizes = {
  xxLarge: 'xxLarge',
  xLarge: 'xLarge',
  large: 'large',
  medium: 'medium',
  base: 'base',
  small: 'small',
};

const textStyles = {
  xxLarge: {
    fontSize: '29px',
    lineHeight: '36px',
  },
  xLarge: {
    fontSize: '24px',
    lineHeight: '32px',
  },
  large: {
    fontSize: '20px',
    lineHeight: '28px',
  },
  medium: {
    fontSize: '16px',
    lineHeight: '24px',
  },
  base: {
    fontSize: '14px',
    lineHeight: '20px',
  },
  small: {
    fontSize: '12px',
    lineHeight: '16px',
  },
};

export function textMixin({ color = '#000', size = textSizes.base, opacity = 0.7 }) {
  return {
    fontFamily: 'Inter, sans-serif',
    color,
    opacity,
    ...textStyles[size],
  };
}

export function linkMixin({
  mainColor = theme.palette.primary.main,
  size = textSizes.base,
  activeColor = '#31A7EB',
} = {}) {
  return {
    ...textMixin({
      color: mainColor,
      opacity: 1,
      size,
    }),

    transition: 'all 0.2s',
    textDecoration: 'none',

    '&:hover': {
      color: activeColor,
      cursor: 'pointer',
    },
  };
}
