import { linkMixin } from '../../../app/theme/mixins';

export default function styles(theme) {
  return {
    wrap: {
      width: '274px',
    },
    link: {
      ...linkMixin({
        mainColor: theme.custom.getDarkRgbaColor(0.8),
        activeColor: theme.palette.primary.main,
      }),
      fontWeight: 500,
      borderRadius: '8px',
      boxSizing: 'border-box',
      height: '56px',
      padding: '12px 20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: '100%',
    },
    linkActive: {
      color: theme.palette.primary.main,
      background: theme.palette.primary.getMainRgba(0.06),
      '& $linkIcon': {
        background: theme.palette.primary.main,

        '& svg path, & svg rect': {
          stroke: theme.custom.whiteColor,
        },
      },
    },
    linkText: {
      marginLeft: '12px',
      flex: 1,
    },
    linkIcon: {
      background: theme.custom.backgroundColor,
      borderRadius: '50%',
      padding: '8px',
      display: 'flex',
      alignItems: 'center',
    },
    dividerWrap: {
      margin: '40px 0 28px',
    },
    divider: {
      backgroundColor: theme.custom.darkColor,
      opacity: '0.1',
    },
  };
}
