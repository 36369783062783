const deleteProfile = {
  confirmYourPasswordToDelete: 'Confirm your password to delete RAK ID.',
  deleteProfileModalBtn: 'Delete RAK ID',
  deleteProfileModalNotice:
    'Are you sure? You’ll immediately lose access to RAK, and all service-related data will be deleted permanently from our servers. You can’t undo this action!',
  deleteProfileModalError:
    'Before you delete RAK ID, you must remove all linked products and services. Some services cannot be removed from here and need you to sign in and remove the RAK ID from the respective service account.',
  deleteProfileInfoModalTitle: 'Delete RAK ID?',
  deleteProfileInfoModalBtn: 'Continue',
  deleteProfileInfoModalText:
    "You're trying to delete your RAK ID, which is linked with other apps and services. You'll no longer be able to use the account, and all your data will be lost permanently.",
  allThisDataWillBeDeleted: 'All this data will be deleted:',
  deleteProfileInfoModalNote1: 'Your shared assets within all RAK services',
  deleteProfileInfoModalNote2: 'Your managed apps and services',
  deleteProfileInfoModalNote3: 'Your profile',
  deleteProfileInfoModalSupportTitle: 'Need help?',
  deleteProfileInfoModalSupportText:
    'Our support team can help you solve your issues and answer any questions you may have.',
  deleteProfileInfoModalSupportLink: 'Contact support',
  deleteInfoTitle: 'Delete RAK ID',
  deleteInfoSubTitle:
    'If you would like to raise an issue, please contact {link} to help you. Do you still wish to delete your RAK ID? You’ll lose all your data and other important information that may be helpful to you.',
  deleteInfoBtn: 'Delete',
  support: 'Support',
};

export default deleteProfile;
