import PropTypes from 'prop-types';

const TERMS_POLICY_MODAL_PROP_TYPES = {
  RULES: {
    isFooter: PropTypes.bool,
    isTerms: PropTypes.bool,
    titleClassName: PropTypes.string,
  },
  DEFAULTS: {
    isFooter: false,
    isTerms: false,
    titleClassName: '',
  },
};

export default TERMS_POLICY_MODAL_PROP_TYPES;
