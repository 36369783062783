import React from 'react';
import ConfirmModal from './ConfirmModal';
import useMFAConfirm from './useMFAConfirm';

const ConfirmMFAFormModal = () => {
  const { open, handleCloseModal, handleSubmit, errorStatus, clearErrorStatus, toggleType, type } =
    useMFAConfirm();

  if (!open) return null;

  return (
    <ConfirmModal
      form="MFA_CONFIRM_LOGIN_FORM"
      onSubmit={handleSubmit}
      handleClose={handleCloseModal}
      errorStatus={errorStatus}
      clearErrorStatus={clearErrorStatus}
      toggleType={toggleType}
      type={type}
    />
  );
};

export default ConfirmMFAFormModal;
