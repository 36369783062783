const verifyEmail = {
  verifyEmailTitle: 'Verify account',
  verifyEmailTitleLogin: 'Verify account to continue',
  verifyEmailSubTitle:
    'We’ve sent an email with a verification code to {email}. Please check your inbox and follow the instructions to finish setting up your RAK ID.{br}{br}Don’t forget to check your spam/junk folder if you cannot find the email within your inbox.',
  verifyEmailFooterHint: 'Still can’t find the email?',
  verifyEmailFooterLink: 'Send it again',
  resendAvailableIn: 'Re-send available in {time}min',
  verifyEmailFormSubmitBtn: 'Verify',
};

export default verifyEmail;
