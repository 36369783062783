import { textMixin, textSizes } from '../../../app/theme/mixins';

export default function styles(theme) {
  return {
    noticeWrap: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 20,

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    noticeIcon: {
      marginRight: 20,
    },
    noticeText: {
      ...textMixin({
        size: textSizes.base,
        opacity: 0.8,
        color: theme.custom.textColor,
      }),
    },
  };
}
