import { linkMixin } from '../../../app/theme/mixins';

export default function styles(theme) {
  return {
    footer: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '11px',
      lineHeight: '16px',
      color: theme.custom.textColor,
      opacity: '0.6',
      justifyContent: 'space-between',

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
        alignItems: 'baseline',
      },
    },
    footerLink: {
      ...linkMixin({
        mainColor: theme.custom.textColor,
        activeColor: 'rgb(85, 85, 85, 0.6)',
      }),
      fontSize: '10px',
      lineHeight: '16px',
    },
    footerLinkIcon: {
      display: 'flex',
      textDecoration: 'none',
      color: 'currentColor',
    },
    termsWrap: {
      display: 'flex',
      alignItems: 'center',

      [theme.breakpoints.down('sm')]: {
        marginBottom: '13px',
      },
    },
    separator: {
      margin: '0 8px',
    },
    icon: {
      width: 16,
      height: 16,
      marginRight: '8px',
    },
  };
}
