import React from 'react';
import PropTypes from 'prop-types';
import { ButtonBase, makeStyles } from '@material-ui/core';
import cn from 'classnames';

import Loader from '../Loader';
import { getDirname } from '../../../utils/parseQuery';
import styles from './styles';

const useStyles = makeStyles(styles, { name: getDirname(__dirname) });

const TextButton = ({ onClick, children, disabled, loading, additionalClass, ...props }) => {
  const classes = useStyles();

  return (
    <ButtonBase
      className={cn(classes.button, additionalClass, { [classes.disabled]: disabled })}
      disabled={disabled}
      onClick={onClick}
      disableRipple
      {...props}
    >
      <span
        className={cn({
          [classes.loading]: loading,
        })}
      >
        {children}
      </span>
      {loading && <Loader size={24} isAbsoluteCentered />}
    </ButtonBase>
  );
};

TextButton.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node.isRequired,
  additionalClass: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

TextButton.defaultProps = {
  loading: false,
  additionalClass: '',
  disabled: false,
};

export default TextButton;
