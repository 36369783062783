import { call, select } from 'redux-saga/effects';
import AuthSelectors from '../../pages/Auth/selectors';
import Profile from '../apiEndpoints/Profile';

export default function* fetchUserProfile(clientId) {
  const clientIdFromSession = yield select(AuthSelectors.getClientId());
  return yield call(Profile.fetch, {
    clientId: clientId || clientIdFromSession,
  });
}
