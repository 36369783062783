import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

import Footer from '../../../components/pageElements/Footer';
import Header from '../../../components/pageElements/Header';
import useMobileScreenDetect from '../../hooks/useMobileScreenDetect';
import styles from './styles';
import SinglePageLayoutProvider from '../../providers/SinglePageLayoutProvider';
import { getDirname } from '../../../utils/parseQuery';

const useStyles = makeStyles(styles, { name: getDirname(__dirname) });

const SinglePageLayout = ({ children }) => {
  const classes = useStyles();

  const { pathname } = useLocation();
  const { isMobile } = useMobileScreenDetect();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <SinglePageLayoutProvider>
      <div className={classes.authLayout}>
        <Header isMobile={isMobile} />

        <div className={classes.authContent}>
          <main className={classes.authMain}>{children}</main>
        </div>

        <footer className={classes.authFooter}>
          <Footer />
        </footer>
      </div>
    </SinglePageLayoutProvider>
  );
};

SinglePageLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SinglePageLayout;
