import React from 'react';
import { makeStyles } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/InputBase';
import cn from 'classnames';

import FieldWrap from '../FieldWrap';

import styles from '../styles/inputStyles';
import TEXT_FORM_FIELD_PROP_TYPES from './types';
import { getDirname } from '../../../utils/parseQuery';

const useStyles = makeStyles(styles, { name: getDirname(__dirname) });

const TextFormField = ({
  input,
  label,
  inputProps,
  disabled,
  meta: { touched, error },
  description,
  tooltipId,
  multiline,
  wrapClassName,
}) => {
  const classes = useStyles();

  return (
    <div>
      <FieldWrap
        label={label}
        touched={touched}
        error={error}
        description={description}
        tooltipId={tooltipId}
      >
        <div
          className={cn(classes.inputWrap, {
            [classes.disabled]: disabled,
            [classes.multiline]: multiline,
            [wrapClassName]: wrapClassName,
          })}
        >
          <FormControl className={classes.input}>
            <Input
              type="text"
              {...input}
              {...inputProps}
              className={classes.input}
              disabled={disabled}
              multiline={multiline}
              classes={{
                input: classes.input,
              }}
              autoComplete="new-password"
              inputProps={{
                autoCapitalize: 'none',
              }}
            />
          </FormControl>
        </div>
      </FieldWrap>
    </div>
  );
};

TextFormField.propTypes = TEXT_FORM_FIELD_PROP_TYPES.RULES;

TextFormField.defaultProps = TEXT_FORM_FIELD_PROP_TYPES.DEFAULTS;

export default TextFormField;
