import PropTypes from 'prop-types';

const TEXT_FORM_FIELD_PROP_TYPES = {
  RULES: {
    input: PropTypes.shape({}),
    inputProps: PropTypes.shape({}),
    meta: PropTypes.shape({
      error: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          id: PropTypes.string,
          values: PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          }),
        }),
      ]),
      touched: PropTypes.bool,
    }),
    tooltipId: PropTypes.string,
    description: PropTypes.node,
    label: PropTypes.node,
    disabled: PropTypes.bool,
    multiline: PropTypes.bool,
    placeholder: PropTypes.string,
    wrapClassName: PropTypes.string,
  },
  DEFAULTS: {
    input: null,
    inputProps: null,
    meta: null,
    disabled: false,
    multiline: false,
    tooltipId: '',
    description: '',
    label: '',
    placeholder: '',
    wrapClassName: '',
  },
};

export default TEXT_FORM_FIELD_PROP_TYPES;
