export default function styles(theme) {
  return {
    wrap: {
      background: theme.custom.whiteColor,
      outline: 'none',
      borderRadius: '10px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        borderRadius: '0',
        height: '100%',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
      },
    },
    content: {
      width: '100%',
      padding: '32px 40px',
      maxHeight: '456px',
      overflowY: 'auto',
      overflowX: 'hidden',
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        flexGrow: 2,
        padding: '32px 20px',
        maxHeight: 'none',
        width: 'auto',
      },
    },
    close: {
      cursor: 'pointer',
    },
    footer: {
      background: theme.custom.backgroundColor,
      padding: '24px 40px',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '10px',
      [theme.breakpoints.down('sm')]: {
        padding: '16px 20px',
        justifyContent: 'flex-end',
        background: 'transparent',
      },
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    header: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '20px',
      lineHeight: '28px',
      letterSpacing: '-0.01em',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
      padding: '20px 40px',

      [theme.breakpoints.down('sm')]: {
        padding: '44px 20px 28px 0',
        marginLeft: '20px',
      },
    },
    buttonBlock: {
      display: 'flex',

      [theme.breakpoints.down('sm')]: {
        flex: 1,
        justifyContent: 'space-between',
        flexDirection: 'row-reverse',
      },
    },
    buttonCancel: {
      color: theme.palette.primary.main,
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '40px',
      marginLeft: '36px',
      '&:disabled': {
        opacity: '0.5',
      },

      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
      },
    },
    onlyCloseButton: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  };
}
