import { call, fork, put, select } from 'redux-saga/effects';
import { differenceInMinutes } from 'date-fns';

import RoutePaths from '../../app/routes/routePaths';
import API_REQ from '../apiActions';
import Api from '../apiEndpoints/Api';
import fetchUserProfile from './fetchUserProfile';
import handleURLQuery from './handleSession';
import watchersSaga from './watchers';
import redirect from '../utils/redirect';
import cache from '../utils/cache';
import getProductType from '../../utils/getProductType';
import Auth from '../apiEndpoints/Auth';
import LOGOUT_STATUSES from '../../pages/Auth/Login/constants';
import { inactivityDurationInMinutes } from '../../components/modals/AutoLogoutModal/constants';
import AuthSelectors from '../../pages/Auth/selectors';

const { REACT_APP_RAK_PORTAL_CLIENT_ID } = process.env;

export default function* authSaga() {
  yield fork(watchersSaga);

  yield put(API_REQ.AUTH.CACHED_TOKEN_VERIFIED(false));

  yield call(Api.version);

  if (window.location.pathname === RoutePaths.Single.ForceLogout()) {
    yield put(API_REQ.AUTH.CACHED_TOKEN_VERIFIED(true));
    return;
  }

  yield call(handleURLQuery);

  const accessToken = cache.getAccessToken();
  const refreshToken = cache.getRefreshToken();
  const lastActionTime = cache.getIsUserActive();

  if (lastActionTime) {
    const difference = differenceInMinutes(new Date(), new Date(+lastActionTime));
    if (difference >= inactivityDurationInMinutes) {
      yield call(Auth.logout, { refreshToken }, { logoutStatus: LOGOUT_STATUSES.expired });
    }
  }

  if (accessToken) {
    const { ok, result } = yield call(fetchUserProfile);
    const { isInternalProduct, isExternalProduct } = getProductType();

    if (ok) {
      if (isExternalProduct) {
        redirect(RoutePaths.Auth.Login.Identity._());
      }
      if (isInternalProduct) {
        const clientId = yield select(AuthSelectors.getClientId());
        yield call(Auth.getConnectionStatus, {
          clientId,
        });
      }
    } else if (result?.code === 'NotConnectedCode') {
      // In case product is not connected we should refetch profile with RAK client_id

      if (isInternalProduct) {
        redirect(RoutePaths.Auth.Connect._());
        yield put(API_REQ.AUTH.CACHED_TOKEN_VERIFIED(true));
        return;
      }

      const { ok: refetchOk } = yield call(fetchUserProfile, REACT_APP_RAK_PORTAL_CLIENT_ID);

      if (refetchOk && isExternalProduct) {
        redirect(RoutePaths.Auth.Login.Identity._());
      }
    }
  }

  yield put(API_REQ.AUTH.CACHED_TOKEN_VERIFIED(true));
}
