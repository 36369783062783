import { combineReducers } from 'redux';

import API_REQ from './apiActions';

const apiReducer = combineReducers({
  version(state = '', { type, payload }) {
    switch (type) {
      case API_REQ.API.VERSION.SUCCESS:
        return payload;
      case API_REQ.API.VERSION.ERROR:
        return '';
      default:
        return state;
    }
  },
});

export default apiReducer;
