import { alpha } from '@material-ui/core';
import { textMixin, textSizes } from '../../../app/theme/mixins';

export default function styles(theme) {
  const appearance = {
    '-webkit-appearance': 'none',
    margin: 0,
  };
  const border = `1px solid ${alpha(theme.custom.textColor, 0.1)}`;
  return {
    root: {
      '& input[type="tel"]': {
        ...textMixin({
          opacity: 1,
          size: textSizes.medium,
        }),
        lineHeight: '48px',
        width: '48px !important',
        height: '48px !important',
        borderRadius: '4px !important',
        border,
        padding: '0 12px',

        '&:focus': {
          border: `1px solid ${alpha(theme.custom.textColor, 0.3)}`,
          caretColor: alpha(theme.custom.textColor, 0.3),
        },

        '&:last-child:not(:focus)': {
          borderRight: border,
        },

        '&:not(:last-child)': {
          marginRight: '8px',

          [theme.breakpoints.down('sm')]: {
            marginRight: '6px',
          },
        },
      },
      '& input:focus + input': {
        borderLeft: `${border} !important`,
      },
      '& input[type=number]': {
        '-moz-appearance': 'textfield',
      },
      '& input::-webkit-outer-spin-button': appearance,
      '& input::-webkit-inner-spin-button': appearance,
      '& input::selection': {
        background: 'transparent',
      },
    },
  };
}
