import createAsyncActionTypes from '../utils/createAsyncActionTypes';
import createAction from '../utils/createAction';

export const API_ACTIONS_CONSTANT = {
  AUTH: {
    CACHED_TOKEN_VERIFIED: 'API_REQ.AUTH.CACHED_TOKEN_VERIFIED',
    TOKEN_EXPIRED: 'API_REQ.AUTH.TOKEN_EXPIRED',
    LOGOUT_RESET_STATUS: 'API_REQ.AUTH.LOGOUT.RESET_STATUS',
    SET_NOTIFICATION_STATUS: 'API_REQ.AUTH.NOTIFICATION_STATUS.SET',
    RESET_NOTIFICATION_STATUS: 'API_REQ.AUTH.NOTIFICATION_STATUS.RESET',
    SET_SESSION: 'API_REQ.AUTH.SET_SESSION',
    CONSENT: 'API_REQ.AUTH.CONSENT',
    CLIENT_SHARING_DATA: 'API_REQ.AUTH.GET_CLIENT_SHARING_DATA',
    SET_CLIENT_ID: 'API_REQ.AUTH.SET_CLIENT_ID',
  },
};

const API_REQ = {
  XHR: createAsyncActionTypes('API_REQ.XHR'),
  API: {
    VERSION: createAsyncActionTypes('API_REQ.API.VERSION'),
  },
  AUTH: {
    CREATE_SESSION: createAsyncActionTypes('API_REQ.AUTH.CREATE_SESSION'),
    CHECK_SESSION: createAsyncActionTypes('API_REQ.AUTH.CHECK_SESSION'),
    LOGIN: createAsyncActionTypes('API_REQ.AUTH.LOGIN'),
    MFA: {
      LOGIN: createAsyncActionTypes('API_REQ.AUTH.MFA.LOGIN'),
      RECOVERY_LOGIN: createAsyncActionTypes('API_REQ.AUTH.MFA.RECOVERY_LOGIN'),
    },
    LOGOUT: createAsyncActionTypes('API_REQ.AUTH.LOGOUT'),
    CONNECT: createAsyncActionTypes('API_REQ.AUTH.CONNECT'),
    GET_CONNECTION_STATUS: createAsyncActionTypes('API_REQ.AUTH.GET_CONNECTION_STATUS'),
    GET_CLIENT_SHARING_DATA: createAsyncActionTypes(API_ACTIONS_CONSTANT.AUTH.CLIENT_SHARING_DATA),
    VERIFY_EMAIL: createAsyncActionTypes('API_REQ.AUTH.VERIFY_EMAIL'),
    RESEND_CODE: createAsyncActionTypes('API_REQ.AUTH.RESEND_CODE'),
    REFRESH_TOKEN: createAsyncActionTypes('API_REQ.AUTH.REFRESH_TOKEN'),
    CACHED_TOKEN_VERIFIED: createAction(API_ACTIONS_CONSTANT.AUTH.CACHED_TOKEN_VERIFIED),
    TOKEN_EXPIRED: createAction(API_ACTIONS_CONSTANT.AUTH.TOKEN_EXPIRED),
    LOGOUT_RESET_STATUS: createAction(API_ACTIONS_CONSTANT.AUTH.LOGOUT_RESET_STATUS),
    SIGN_UP: createAsyncActionTypes('API_REQ.AUTH.SIGN_UP'),
    SET_SESSION: createAction(API_ACTIONS_CONSTANT.AUTH.SET_SESSION),
    FORGOT_PASSWORD: createAsyncActionTypes('API_REQ.AUTH.FORGOT_PASSWORD'),
    SET_NOTIFICATION_STATUS: createAction(API_ACTIONS_CONSTANT.AUTH.SET_NOTIFICATION_STATUS),
    RESET_NOTIFICATION_STATUS: createAction(API_ACTIONS_CONSTANT.AUTH.RESET_NOTIFICATION_STATUS),
    CONFIRM_PASSWORD: createAsyncActionTypes('API_REQ.AUTH.CONFIRM_PASSWORD'),
    CONSENT: createAction(API_ACTIONS_CONSTANT.AUTH.CONSENT),
    SET_CLIENT_ID: createAction(API_ACTIONS_CONSTANT.AUTH.SET_CLIENT_ID),
    VALIDATE_PASSWORD: createAsyncActionTypes('API_REQ.AUTH.VALIDATE_PASSWORD'),
  },
  PROFILE: {
    FETCH: createAsyncActionTypes('API_REQ.PROFILE.FETCH'),
    UPDATE: createAsyncActionTypes('API_REQ.PROFILE.UPDATE'),
    UPDATE_PHOTO: createAsyncActionTypes('API_REQ.PROFILE.UPDATE_PHOTO'),
    DISCONNECT: createAsyncActionTypes('API_REQ.PROFILE.DISCONNECT'),
    TERMINATE: createAsyncActionTypes('API_REQ.PROFILE.TERMINATE'),
    EXPORT: createAsyncActionTypes('API_REQ.PROFILE.EXPORT'),
  },
  MFA: {
    GENERATE: createAsyncActionTypes('API_REQ.MFA.GENERATE'),
    CREATE: createAsyncActionTypes('API_REQ.MFA.CREATE'),
    DELETE: createAsyncActionTypes('API_REQ.MFA.DELETE'),
    RESCUE_CODE: {
      FETCH: createAsyncActionTypes('API_REQ.MFA.RESCUE_CODE.FETCH'),
      GENERATE: createAsyncActionTypes('API_REQ.MFA.RESCUE_CODE.GENERATE'),
      DOWNLOAD: createAsyncActionTypes('API_REQ.MFA.RESCUE_CODE.DOWNLOAD'),
    },
  },
};

export default API_REQ;
