import { useCallback, useMemo } from 'react';
import Auth from '../../api/apiEndpoints/Auth';

import { runSaga } from '../redux/configureStore';
import cache from '../../api/utils/cache';

export default function useLogout() {

  const handleLogout = useCallback(
    async (logoutStatus) => {
      const refreshToken = cache.getRefreshToken();

      await runSaga(
        Auth.logout,
        { refreshToken },
        { logoutStatus },
      ).toPromise();
    },
    [],
  );

  return useMemo(() => ({ handleLogout }), [handleLogout]);
}
