export const FA_LOGIN_TYPE = {
  FA_CODE: 'faCode',
  RESCUE_CODE: 'rescueCode',
};

export const FA_LOGIN_INFO_BY_TYPE = {
  [FA_LOGIN_TYPE.FA_CODE]: {
    hint: `login.confirmMFAModal.${FA_LOGIN_TYPE.FA_CODE}.codeText`,
    label: `login.confirmMFAModal.${FA_LOGIN_TYPE.FA_CODE}.codeLabel`,
    toggleText: `login.confirmMFAModal.${FA_LOGIN_TYPE.FA_CODE}.toggleBtnText`,
  },
  [FA_LOGIN_TYPE.RESCUE_CODE]: {
    hint: `login.confirmMFAModal.${FA_LOGIN_TYPE.RESCUE_CODE}.codeText`,
    label: `login.confirmMFAModal.${FA_LOGIN_TYPE.RESCUE_CODE}.codeLabel`,
    toggleText: `login.confirmMFAModal.${FA_LOGIN_TYPE.RESCUE_CODE}.toggleBtnText`,
    warning: `login.confirmMFAModal.${FA_LOGIN_TYPE.RESCUE_CODE}.warning`,
  },
};
