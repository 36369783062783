import React, { createContext } from 'react';
import PropTypes from 'prop-types';

export const SinglePageLayoutContext = createContext({ isSinglePage: false });

const SinglePageLayoutProvider = ({ children }) => (
  <SinglePageLayoutContext.Provider value={{ isSinglePage: true }}>
    {children}
  </SinglePageLayoutContext.Provider>
);

SinglePageLayoutProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SinglePageLayoutProvider;
