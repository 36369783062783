import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

import TermsPolicyModal from '../../../components/modals/TermsPolicyModal';
import ApiSelectors from '../../../api/selectors';
import RoutePaths from '../../routes/routePaths';
import { getDirname } from '../../../utils/parseQuery';
import ConfirmMFAFormModal from '../../../components/modals/ConfirmMFAFormModal';
import styles from './styles';
import AuthSelectors from '../../../pages/Auth/selectors';

const useStyles = makeStyles(styles, { name: getDirname(__dirname) });

const UnAuthorizeLayout = ({ children }) => {
  const classes = useStyles();
  const apiVersion = useSelector(ApiSelectors.getApiVersion());
  const year = new Date().getFullYear();
  const { pathname, search } = useLocation();
  const isRAKPlatform = useSelector(AuthSelectors.isRAKPlatform());

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className={classes.root}>
      <div className={classes.layout}>
        <main>{children}</main>

        <ConfirmMFAFormModal />

        <footer className={classes.footer}>
          <div className={classes.policiesWrap}>
            <Link
              to={{
                pathname: RoutePaths.Single.About(),
                search: isRAKPlatform ? search : undefined,
              }}
              className={classes.footerLink}
              target={isRAKPlatform ? undefined : '_blank'}
            >
              <FormattedMessage id="common.footer.aboutPortalTitle" />
            </Link>

            <div className={classes.separator}>·</div>

            <TermsPolicyModal isFooter />

            <div className={classes.separator}>·</div>

            <TermsPolicyModal isFooter isTerms />
          </div>

          <FormattedMessage
            id="common.footer.footerCopyright"
            values={{ version: apiVersion, year }}
          />
        </footer>
      </div>
    </div>
  );
};

UnAuthorizeLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UnAuthorizeLayout;
