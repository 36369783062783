import PropTypes from 'prop-types';

const CUSTOM_MODAL_PROP_TYPES = {
  RULES: {
    contentClass: PropTypes.string,
    children: PropTypes.node.isRequired,
    disableSubmit: PropTypes.bool,
    submitText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    cancelText: PropTypes.string,
    modalTrigger: PropTypes.node,
    handleClose: PropTypes.func,
    isSubmitting: PropTypes.bool,
    withoutTitle: PropTypes.bool,
    onSubmit: PropTypes.func,
    onlyClose: PropTypes.bool,
    showClose: PropTypes.bool,
    titleId: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    isOpen: PropTypes.bool,
    modalProps: PropTypes.objectOf(PropTypes.any),
    form: PropTypes.string,
  },
  DEFAULTS: {
    titleId: 'advancedFrequencySettings',
    submitText: 'saveSettings',
    disableSubmit: false,
    handleClose: () => {},
    cancelText: 'cancel',
    onSubmit: () => {},
    modalTrigger: null,
    withoutTitle: false,
    isSubmitting: false,
    onlyClose: false,
    showClose: true,
    isOpen: false,
    contentClass: '',
    modalProps: {},
    form: '',
  },
};

export default CUSTOM_MODAL_PROP_TYPES;
