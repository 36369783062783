import API_REQ from '../../api/apiActions';

function userProfileReducer(state = null, { type, payload, meta }) {
  switch (type) {
    case API_REQ.PROFILE.FETCH.SUCCESS:
    case API_REQ.PROFILE.UPDATE.SUCCESS: {
      return payload;
    }
    case API_REQ.PROFILE.UPDATE_PHOTO.SUCCESS: {
      return { ...state, profilePhoto: payload };
    }
    case API_REQ.PROFILE.DISCONNECT.SUCCESS: {
      const {
        input: { clientId },
      } = meta;
      const { connectedProducts: prevConnectedProducts, ...rest } = state;
      const connectedProducts = prevConnectedProducts.filter(
        (product) => product.clientId !== clientId,
      );
      return { ...rest, connectedProducts };
    }
    case API_REQ.MFA.CREATE.SUCCESS: {
      return { ...state, mfa: true, mfaUpdatedAt: new Date().toISOString() };
    }
    case API_REQ.MFA.DELETE.SUCCESS: {
      return { ...state, mfa: false, mfaUpdatedAt: null };
    }
    default:
      return state;
  }
}

export default userProfileReducer;
