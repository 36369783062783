import React from 'react';
import PropTypes from 'prop-types';
import { ConnectedRouter } from 'connected-react-router';

import history from '../redux/configureBrowserHistory';

function HistoryProvider({ children }) {
  return (
    <ConnectedRouter history={history}>
      {children}
    </ConnectedRouter>
  );
}

HistoryProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HistoryProvider;
