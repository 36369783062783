import React from 'react';
import PropTypes from 'prop-types';

const CUSTOM_BUTTON_PROP_TYPES = {
  RULES: {
    size: PropTypes.oneOf(['large', 'medium', 'small', 'xSmall']),
    variant: PropTypes.oneOf(['contained', 'outlined']),
    color: PropTypes.oneOf(['primary', 'secondary']),
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    fullWidth: PropTypes.bool,
    type: PropTypes.oneOf(['button', 'submit', 'reset']),
    onClick: PropTypes.func,
    children: PropTypes.node,
    additionalClassName: PropTypes.string,
  },
  DEFAULTS: {
    size: 'medium',
    variant: 'contained',
    color: 'primary',
    disabled: false,
    loading: false,
    fullWidth: false,
    type: 'button',
    onClick: () => null,
    children: <div />,
    additionalClassName: '',
  },
};

export default CUSTOM_BUTTON_PROP_TYPES;
