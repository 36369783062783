const registration = {
  registrationPageTitle: 'Create RAK ID account',
  registrationPageSubtitle:
    'Create your RAK account and gain access to all RAK services with a single email and password.',
  registrationPageSignInHint1: 'Already have an account?',
  registrationPageSignInHint2: 'Sign in',
  registrationPageCustomError1: 'You already have a RAK ID with this email. Try ',
  registrationPageCustomError2: 'Sign in.',
  registrationPageFormTermsLink: 'I agree with the',
  registrationPageFormTermsLink2:
    'and acknowledge that my personal data will be processed in accordance with the',
  registrationPageFormSubmitBtn: 'Sign up',
  registrationPageHintInfo:
    'You have got an invitation to join a {platform} organization. Please, register a RAK ID to start working.',
};

export default registration;
