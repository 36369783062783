import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ToastContainer } from 'react-toastify';

import styles from './styles';
import { toastDuration } from './constants';
import { getDirname } from '../../../utils/parseQuery';

const useStyles = makeStyles(styles, { name: getDirname(__dirname) });

const StyledToast = () => {
  const classes = useStyles();

  return (
    <ToastContainer
      className={classes.root}
      autoClose={toastDuration}
      position="bottom-center"
      draggable={false}
    />
  );
};

export default StyledToast;
