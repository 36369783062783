import React from 'react';
import { FormattedMessage } from 'react-intl';
import { put, takeEvery } from 'redux-saga/effects';
import API_REQ from '../../api/apiActions';
import { showNotification } from '../../app/actions';
import { notificationsTypes } from '../../components/UI/Notification/constants';

function* handleActivateMFAError() {
  yield put(
    showNotification({
      type: notificationsTypes.error,
      message: <FormattedMessage id="security.activateModal.failed" />,
    }),
  );
}

function* handleDeleteMFASuccess() {
  yield put(
    showNotification({
      type: notificationsTypes.success,
      message: <FormattedMessage id="security.disableModal.success" />,
    }),
  );
}

export default function* securitySaga() {
  yield takeEvery(API_REQ.MFA.GENERATE.ERROR, handleActivateMFAError);
  yield takeEvery(API_REQ.MFA.DELETE.SUCCESS, handleDeleteMFASuccess);
}
