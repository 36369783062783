import PropTypes from 'prop-types';

const FIELD_TOOLTIP_PROP_TYPES = {
  RULES: {
    tooltipId: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
    children: PropTypes.node.isRequired,
    contentClass: PropTypes.string,
    tooltipClass: PropTypes.string,
    arrowClass: PropTypes.string,
    buttonClass: PropTypes.string,
    disable: PropTypes.bool,
  },
  DEFAULTS: {
    tooltipClass: '',
    contentClass: '',
    arrowClass: '',
    buttonClass: '',
    disable: false,
  },
};

export default FIELD_TOOLTIP_PROP_TYPES;
