import { compose } from 'recompose';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, useLocation, withRouter } from 'react-router-dom';

import AuthorizeLayout from '../layouts/AuthorizeLayout';
import RoutePaths from './routePaths';
import AuthSelectors from '../../pages/Auth/selectors';
import RouteWrapper from './RouteWrapper';
import useProductType from '../hooks/useProductType';

function AuthorizedRoute({ ...props }) {
  const isSignedIn = useSelector(AuthSelectors.getIsSigned());
  const { isExternalProduct } = useProductType();
  const { search } = useLocation();

  if (isExternalProduct) {
    return <Redirect to={{ pathname: RoutePaths.Auth.Login.Identity._(), search }} />;
  }

  if (isSignedIn) {
    return (
      <AuthorizeLayout>
        <RouteWrapper>
          <Route {...props} />
        </RouteWrapper>
      </AuthorizeLayout>
    );
  }

  return <Redirect to={RoutePaths.Auth.Login._()} />;
}

export default compose(withRouter)(AuthorizedRoute);
