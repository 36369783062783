import { call, select } from 'redux-saga/effects';
import AuthSelectors from '../../pages/Auth/selectors';
import Auth from '../apiEndpoints/Auth';

function* updateAccessToken({ payload: refreshToken }) {
  const clientId = yield select(AuthSelectors.getClientId());
  yield call(Auth.refreshToken, {
    refreshToken,
    grantType: 'refresh_token',
    clientId,
  });
}

export default updateAccessToken;
