import { useEffect, useMemo, useState } from 'react';

export default function useMobileScreenDetect(breakpoint = 960) {
  const [isMobileDevice, setMobile] = useState(false);
  const [isMobileWidth, setWidth] = useState(window.innerWidth < 960);

  useEffect(() => {
    function resize() {
      setWidth(window.innerWidth < breakpoint);
    }
    window.addEventListener('resize', resize);

    return () => {
      window.addEventListener('resize', resize);
    };
  }, [breakpoint, isMobileWidth]);

  useEffect(() => {
    const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
    const mobile = Boolean(
      userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i),
    );
    setMobile(mobile);
  }, []);

  return useMemo(
    () => ({
      isMobile: isMobileDevice || isMobileWidth,
    }),
    [isMobileDevice, isMobileWidth],
  );
}
