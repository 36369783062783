import { linkMixin } from '../../../../../app/theme/mixins';

export default function styles(theme) {
  return {
    navigationWrap: {
      display: 'flex',
      alignItems: 'center',

      '& $link': {
        marginRight: '20px',
        [theme.breakpoints.down('sm')]: {
          marginRight: 0,
        },
      },

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        padding: '0 20px',
      },
    },
    link: {
      ...linkMixin(),

      [theme.breakpoints.down('sm')]: {
        marginBottom: '22px',
      },
    },
    button: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        padding: '0',
      },
    },
  };
}
