import React from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import { ReactComponent as ErrorIcon } from '../../../assets/icons/other/error.svg';
import { ReactComponent as InfoIcon } from '../../../assets/icons/circles/info.svg';

import styles from './styles';
import { getDirname } from '../../../utils/parseQuery';

const useStyles = makeStyles(styles, { name: getDirname(__dirname) });

const Notification = ({ data }) => {
  const classes = useStyles({ data });

  return (
    <div className={classes.wrap}>
      <div className={classes.header}>
        {['error', 'warning'].includes(data.type) ? (
          <ErrorIcon className={classes.icon} />
        ) : (
          <InfoIcon className={classes.icon} />
        )}
        <div className={classes.title}>
          <span>{data.type}</span> message
        </div>
      </div>
      <div className={classes.content}>
        <div className={classes.subTitle}>{data.message}</div>
      </div>
    </div>
  );
};

Notification.propTypes = {
  data: PropTypes.objectOf(PropTypes.string),
};

Notification.defaultProps = {
  data: {},
};

export default Notification;
