import { textMixin, textSizes } from '../../../app/theme/mixins';

export default function styles(theme) {
  return {
    root: {
      ...textMixin({ size: textSizes.base, opacity: 1 }),
      borderRadius: '5px',
      padding: '16px 20px',
      display: 'flex',
      flexDirection: 'column',
    },
    error: {
      backgroundColor: theme.custom.errorLight,
      color: theme.custom.errorColor,
    },
    info: {
      backgroundColor: theme.custom.infoLight,
      color: theme.palette.primary.main,
    },
    success: {
      backgroundColor: theme.custom.successLight,
      color: theme.custom.successColor,
    },
  };
}
