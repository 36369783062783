export default function styles(theme) {
  return {
    tooltip: {
      width: '200px',
      height: '80px',
      background: theme.custom.whiteColor,
      boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
      borderRadius: '4px',
      opacity: 0.5,
      textAlign: 'center',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '16px',
      padding: '10px',
    },
    arrow: {
      color: theme.custom.whiteColor,
    },
    tooltipContent: {
      color: theme.custom.textColor,
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '16px',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  };
}
