import PropTypes from 'prop-types';

const AVATAR_PROP_TYPES = {
  RULES: {
    name: PropTypes.string.isRequired,
    photo: PropTypes.string,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    withBorder: PropTypes.bool,
  },
  DEFAULTS: {
    photo: null,
    size: 'medium',
    withBorder: true,
  },
};

export default AVATAR_PROP_TYPES;
