import { call, takeEvery } from 'redux-saga/effects';
import React from 'react';
import { toast } from 'react-toastify';

import Notification from '../components/UI/Notification';
import { SHOW_NOTIFICATION } from './actions';

export function* handleNotification(action) {
  const data = action.payload;
  yield call(toast[data.type], <Notification data={data} />);
}

export default function* appSaga() {
  yield takeEvery(SHOW_NOTIFICATION, handleNotification);
}
