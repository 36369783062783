import { linkMixin } from '../../../app/theme/mixins';

export default function styles() {
  return {
    button: {
      ...linkMixin(),
      whiteSpace: 'nowrap',
    },
    disabled: {
      opacity: 0.5,
    },
    loading: {
      opacity: 0,
    },
  };
}
