import React from 'react';
import cn from 'classnames';
import { makeStyles } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText/FormHelperText';
import { FormattedMessage } from 'react-intl';

import FieldTooltip from '../FieldTooltip';

import styles from './styles';
import tooltipIcon from '../../../assets/icons/circles/tooltip.svg';
import FIELD_WRAP_PROP_TYPES from './types';
import { getDirname } from '../../../utils/parseQuery';

const useStyles = makeStyles(styles, { name: getDirname(__dirname) });

const FieldWrap = ({ children, label, touched, error, description, tooltipId }) => {
  const classes = useStyles();

  const errorMessageProps = error?.values
    ? { id: error.message, values: error.values }
    : { id: error };

  return (
    <div>
      {label && (
        <div className={classes.labelWrap}>
          <span
            className={cn(classes.label, {
              [classes.error]: error && touched,
            })}
          >
            {label}
          </span>
          {tooltipId && (
            <div>
              <FieldTooltip tooltipId={tooltipId}>
                <img src={tooltipIcon} alt="icon" />
              </FieldTooltip>
            </div>
          )}
        </div>
      )}

      {children}

      {!(error && touched) && !!description ? (
        <FormHelperText className={classes.label}>{description}</FormHelperText>
      ) : null}

      {error && touched && (
        <FormHelperText
          className={cn(classes.label, {
            [classes.error]: error && touched,
          })}
          error
        >
          <FormattedMessage {...errorMessageProps} />
        </FormHelperText>
      )}
    </div>
  );
};

FieldWrap.propTypes = FIELD_WRAP_PROP_TYPES.RULES;

FieldWrap.defaultProps = FIELD_WRAP_PROP_TYPES.DEFAULTS;

export default FieldWrap;
