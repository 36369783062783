import cache from '../utils/cache';

export function setCache({ accessToken, refreshToken, idToken }) {
  cache.setAccessToken(accessToken);
  cache.setRefreshToken(refreshToken);
  cache.setIdToken(idToken);
}

export function clearCache() {
  cache.setAccessToken('');
  cache.setRefreshToken('');
  cache.setIdToken('');
  cache.setIsUserActive('');
}
