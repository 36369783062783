import { connect } from 'react-redux';
import { compose } from 'recompose';

import AuthSelectors from '../../pages/Auth/selectors';
import withLoader from './withLoader';

const withInitialLoader = compose(
  connect((state) => ({
    loading: AuthSelectors.getInitialLoading()(state),
  })),
  withLoader(),
);

export default withInitialLoader;
