const validations = {
  fieldCannotBeEmpty: "This field cannot be empty.",
  pleaseTypeREMOVE:"Please type 'REMOVE'",
  pleaseTypeTerminate:"Please type 'TERMINATE'",
  passwordsDontMatch:"Passwords don't match",
  maxChars: 'This field should not be longer than {value}.',
  minChars:'This field should be at least {value} characters long.',
  invalidCharacters:'Invalid characters. Please check the input.',
  enterValidPhoneNumber:'Please enter a valid phone number.',
  passwordCantStartOrEndWithBlankSpace:"Your password can't start or end with a blank space.",
}

export default validations
