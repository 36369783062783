import { createSelector } from 'reselect';

const apiState = (state) => state.api;

const ApiSelectors = {
  getApiVersion() {
    return createSelector(
      apiState,
      ({ version }) => version || '0.0.0',
    );
  },
};

export default ApiSelectors;
