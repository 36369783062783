import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const usePush = () => {
  const { push } = useHistory();
  const { search } = useLocation();

  return useCallback((pathname) => push({ pathname, search }), [push, search]);
};

export default usePush;
