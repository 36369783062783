import {
  ButtonBase,
  ClickAwayListener,
  Divider,
  Grow,
  IconButton,
  List,
  ListItem,
  Popper,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import UserProfileSelectors from '../../../../../pages/UserProfile/selectors';
import Avatar from '../../../../UI/Avatar';
import ProfileIdentityItem from '../../../../UI/ProfileIdentityItem';
import LogoutForm from '../LogoutForm';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/crosses/close.svg';
import styles from './styles';
import { getDirname } from '../../../../../utils/parseQuery';

const useStyles = makeStyles(styles, { name: getDirname(__dirname) });

const UserMenu = ({ handleLogout }) => {
  const classes = useStyles();

  const userProfile = useSelector(UserProfileSelectors.getUserProfile());

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  if (!userProfile) return null;

  return (
    <div>
      <Popper
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        className={classes.popper}
        transition
        placement="bottom"
        disablePortal
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClickAway}>
            <Grow {...TransitionProps}>
              <List className={classes.menuWrap}>
                <div className={classes.header}>
                  <IconButton
                    color="inherit"
                    className={classes.headerIconWrap}
                    aria-label="close user-menu"
                    onClick={handleClickAway}
                  >
                    <CloseIcon className={classes.closeIcon} />
                  </IconButton>
                </div>
                <ListItem className={cn(classes.menuItem, classes.identity)}>
                  <ProfileIdentityItem
                    photo={userProfile.profilePhoto}
                    email={userProfile.email}
                    name={userProfile.name}
                    size="large"
                  />
                </ListItem>
                <Divider className={classes.divider} />
                <ListItem className={cn(classes.menuItem, classes.logout)}>
                  <LogoutForm form="LOGOUT_FORM" onSubmit={handleLogout} />
                </ListItem>
              </List>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>

      <ButtonBase onClick={handleClick} disableRipple>
        <Avatar
          photo={userProfile.profilePhoto}
          name={userProfile.name || userProfile.email}
          size="small"
        />
      </ButtonBase>
    </div>
  );
};

UserMenu.propTypes = {
  handleLogout: PropTypes.func.isRequired,
};

export default UserMenu;
