import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { Divider, SvgIcon, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import SinglePageNavigation from '../Header/components/SinglePageNavigation';
import ProfileAvatar from './components/ProfileAvatar';
import styles from './styles';

import { SinglePageLayoutContext } from '../../../app/providers/SinglePageLayoutProvider';
import UserProfileSelectors from '../../../pages/UserProfile/selectors';
import menuItems from './constants';
import { getDirname } from '../../../utils/parseQuery';

const useStyles = makeStyles(styles, { name: getDirname(__dirname) });

const Sidebar = ({ withProfile, closeDrawer }) => {
  const classes = useStyles();

  const userProfile = useSelector(UserProfileSelectors.getUserProfile());
  const { isSinglePage } = useContext(SinglePageLayoutContext);

  const navLinkClickHandler = useCallback(() => {
    closeDrawer({ preventDefault: () => {} });
  }, [closeDrawer]);

  return (
    <div className={classes.wrap}>
      {withProfile && (
        <>
          <ProfileAvatar userProfile={userProfile} />
          <div className={classes.dividerWrap}>
            <Divider className={classes.divider} />
          </div>
        </>
      )}

      {isSinglePage ? (
        <SinglePageNavigation />
      ) : (
        menuItems.map((item) => (
          <NavLink
            key={item.title}
            to={item.url}
            className={classes.link}
            activeClassName={classes.linkActive}
            onClick={navLinkClickHandler}
          >
            <div className={classes.linkIcon}>
              <SvgIcon component={item.icon} />
            </div>
            <div className={classes.linkText}>
              <FormattedMessage id={item.title} />
            </div>
          </NavLink>
        ))
      )}
    </div>
  );
};

Sidebar.propTypes = {
  closeDrawer: PropTypes.func,
  withProfile: PropTypes.bool,
};

Sidebar.defaultProps = {
  closeDrawer: () => {},
  withProfile: false,
};

export default Sidebar;
