const common = {
  and: 'and',
  sidebar: {
    profile: 'Your info',
    appsAndServices: 'Manage apps and services',
    security: 'Security',
  },
  authErrorPage: {
    authErrorTitle: 'Authorization error',
    authErrorCode: 'Error 401: invalid_client',
    authErrorMessage: 'The 0Auth client was not found.',
  },
  modals: {
    customModalSubmitBtn: 'Confirm',
    customModalNextBtn: 'Next',
    customModalCancelText: 'Cancel',
    customModalCancelText2: 'No, go back',
    customModalCloseText: 'Close',
  },
  avatar: {
    avatarProfileModalTitle: 'Upload profile photo',
    avatarProfileModalBtn: 'Save',
  },
  header: {
    headerSignOutBtn: 'Sign out',
    headerLogoText: 'ID Portal',
  },
  baseInfo: {
    baseInfoTitle: 'One account for all things RAK.',
    baseInfoSubTitle:
      'RAK ID is your single, central place to personalise your experience across all RAK apps and services. Sign in to access and customise your preferences at any time.',
    baseInfoLabelEmail: 'Email',
    baseInfoPasswordLabel: 'Password',
    baseInfoPasswordBtn: 'Reset password',
    baseInfoLanguageLabel: 'Language',
    baseInfoResetError: 'Unable to complete the operation',
  },
  exportInfo: {
    exportInfoTitle: 'Export profile',
    exportInfoBtn: 'Export',
    exportInfoMessage: 'Want to know how we handle your data? Please refer to our {link}.',
    exportInfoError: 'Something went wrong. Please try again later.',
    exportInfoFilename: 'RAKID-{name}-{date}.pdf',
  },
  autoLogoutModal: {
    autoLogoutModalTitle: 'Session is about to expire',
    autoLogoutModalText:
      'Are you still here? Your session is about to expire because of inactivity timeout.',
    autoLogoutModalBtn: "I'm here",
  },
  termsModal: {
    title: 'Terms and conditions',
    triggerBtn: 'Terms and conditions',
    closeBtn: 'Close',
  },
  privacyModal: {
    title: 'Privacy notice',
    triggerBtn: 'Privacy notice',
  },
  footer: {
    aboutPortalTitle: 'About RAK ID portal',
    footerCopyright:
      '© 2020 - {year} RAKwireless Technology Limited. All rights reserved, RAK ID v.{version}',
  },
  captcha: {
    thisSiteIsProtectedByReCaptcha: 'This site is protected by reCAPTCHA and Google.',
    termsAndPolicyApply: '{privacyPolicy} and {termsOfService} apply.',
    privacyPolicy: 'Privacy policy',
    termsOfService: 'Terms of service',
  },
};

export default common;
