import { combineReducers } from 'redux';

import API_REQ, { API_ACTIONS_CONSTANT } from '../../api/apiActions';
import CONNECTION_RESPONSES from './constants';

const isNotConnected = (status) => status === CONNECTION_RESPONSES.exists_not_connected;

const isMFAPayload = (payload) => !!payload.session;

const authReducer = combineReducers({
  allowedProperties(state = '', { type, payload }) {
    switch (type) {
      case API_REQ.AUTH.GET_CLIENT_SHARING_DATA.SUCCESS:
        // eslint-disable-next-line camelcase
        return payload?.allowed_properties_ui?.split(',') || '';
      default:
        return state;
    }
  },

  clientId(state = '', { type, payload }) {
    switch (type) {
      case API_ACTIONS_CONSTANT.AUTH.SET_CLIENT_ID:
      case API_ACTIONS_CONSTANT.AUTH.SET_SESSION:
        return payload?.clientId || '';
      default:
        return state;
    }
  },

  platform(state = '', { type, payload }) {
    switch (type) {
      case API_ACTIONS_CONSTANT.AUTH.SET_SESSION:
        return payload?.platform || '';
      default:
        return state;
    }
  },

  signedIn(state = false, { type, payload }) {
    switch (type) {
      case API_REQ.AUTH.LOGIN.SUCCESS:
      case API_REQ.AUTH.MFA.LOGIN.SUCCESS:
      case API_REQ.AUTH.MFA.RECOVERY_LOGIN.SUCCESS: {
        if (isMFAPayload(payload)) {
          return false;
        }
        return !isNotConnected(payload?.connectionStatus);
      }
      case API_REQ.AUTH.REFRESH_TOKEN.SUCCESS:
      case API_REQ.PROFILE.FETCH.SUCCESS:
        return true;
      case API_REQ.AUTH.REFRESH_TOKEN.ERROR:
      case API_REQ.PROFILE.FETCH.ERROR:
      case API_REQ.AUTH.LOGOUT.SUCCESS:
        return false;
      default:
        return state;
    }
  },

  exchangeTokenPending(state = false, { type }) {
    switch (type) {
      case API_REQ.AUTH.REFRESH_TOKEN.START:
        return true;
      case API_REQ.AUTH.REFRESH_TOKEN.SUCCESS:
      case API_REQ.AUTH.REFRESH_TOKEN.ERROR:
        return false;
      default:
        return state;
    }
  },

  cachedAuthTokenVerified(state = false, { type, payload }) {
    switch (type) {
      case API_ACTIONS_CONSTANT.AUTH.CACHED_TOKEN_VERIFIED:
        return payload;
      default:
        return state;
    }
  },

  connectionStatus(state = '', { type, payload }) {
    switch (type) {
      case API_REQ.AUTH.LOGIN.SUCCESS:
      case API_REQ.AUTH.MFA.LOGIN.SUCCESS:
      case API_REQ.AUTH.MFA.RECOVERY_LOGIN.SUCCESS: {
        if (isMFAPayload(payload)) {
          return false;
        }
        return payload.connectionStatus;
      }
      case API_REQ.AUTH.GET_CONNECTION_STATUS.SUCCESS:
        return payload.result;

      case API_REQ.AUTH.LOGOUT.SUCCESS:
        return '';
      default:
        return state;
    }
  },

  logoutStatus(state = '', { type, meta }) {
    switch (type) {
      case API_REQ.AUTH.LOGOUT.SUCCESS:
        return meta.logoutStatus || '';
      case API_ACTIONS_CONSTANT.AUTH.LOGOUT_RESET_STATUS:
        return '';
      default:
        return state;
    }
  },

  notificationStatus(state = '', { type, payload }) {
    switch (type) {
      case API_REQ.AUTH.CONFIRM_PASSWORD.SUCCESS:
        return 'resetSuccess';
      case API_REQ.AUTH.VERIFY_EMAIL.SUCCESS:
        return 'verifySuccess';
      case API_ACTIONS_CONSTANT.AUTH.SET_NOTIFICATION_STATUS:
        return payload;
      case API_ACTIONS_CONSTANT.AUTH.RESET_NOTIFICATION_STATUS:
        return '';
      default:
        return state;
    }
  },

  MFASignInSession(state = null, { type, payload }) {
    switch (type) {
      case API_REQ.AUTH.LOGIN.SUCCESS: {
        if (isMFAPayload(payload)) {
          return payload?.session;
        }

        return null;
      }
      case API_REQ.AUTH.MFA.LOGIN.SUCCESS:
      case API_REQ.AUTH.MFA.RECOVERY_LOGIN.SUCCESS:
      case API_REQ.AUTH.LOGOUT.SUCCESS:
        return null;
      default:
        return state;
    }
  },
});

export default authReducer;
