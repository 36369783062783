import { Divider, Drawer as DrawerComponent, IconButton, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import { ReactComponent as CloseIcon } from '../../../../../assets/icons/crosses/close.svg';
import Sidebar from '../../../Sidebar';
import styles from './styles';
import { getDirname } from '../../../../../utils/parseQuery';

const useStyles = makeStyles(styles, { name: getDirname(__dirname) });

function Drawer({ isOpen, toggleDrawer }) {
  const classes = useStyles();

  return (
    <DrawerComponent
      className={classes.drawer}
      anchor="left"
      open={isOpen}
      BackdropProps={{
        classes: {
          root: classes.drawerOverlay,
        },
      }}
      onClose={toggleDrawer(false)}
    >
      <div className={classes.drawerWrap}>
        <div>
          <div className={classes.drawerHeader}>
            <IconButton
              color="inherit"
              aria-label="close drawer"
              onClick={toggleDrawer(false)}
              edge="start"
              disableRipple
            >
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          </div>
          <Divider className={classes.divider} />
        </div>
        <div className={classes.drawerSidebarWrap}>
          <Sidebar closeDrawer={toggleDrawer(false)} />
        </div>
      </div>
    </DrawerComponent>
  );
}

Drawer.propTypes = {
  isOpen: PropTypes.bool,
  toggleDrawer: PropTypes.func.isRequired,
};

Drawer.defaultProps = {
  isOpen: false,
};

export default Drawer;
