import { all, put, takeEvery } from 'redux-saga/effects';
import get from 'lodash.get';

import API_REQ from '../../api/apiActions';
import { showNotification } from '../actions';
import { notificationsTypes } from '../../components/UI/Notification/constants';
import appSaga from '../saga';
import authSaga from '../../api/apiSaga';
import userProfileSaga from '../../pages/UserProfile/saga';
import securitySaga from '../../pages/Security/saga';

function* rootSaga() {
  yield takeEvery(API_REQ.XHR.ERROR, notifyApiRequestError);

  yield all([authSaga(), appSaga(), userProfileSaga(), securitySaga()]);
}

function* notifyApiRequestError(action) {
  const errorMessage = get(action, ['payload', 'message']);

  const errorStatus = get(action, ['payload', 'status']);

  const muteError = get(action, ['meta', 'muteError'], false);

  if (!muteError && errorStatus !== 400) {
    yield put(
      showNotification(
        {
          type: notificationsTypes.error,
          message: errorMessage,
        },
        {},
      ),
    );
  }
}

export default rootSaga;
