import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'recompose';
import cn from 'classnames';

import CustomModal from '../CustomModal';
import MFACodeInput from '../../formElements/MFACodeInput';
import TextFormField from '../../formElements/TextField';
import ErrorWithControl from '../../UI/ErrorWithControl';
import Divider from '../../UI/Divider';
import NotificationBlock from '../../UI/NotificationBlock';
import { notificationsTypes } from '../../UI/Notification/constants';
import TextButton from '../../UI/TextButton';
import { FA_LOGIN_INFO_BY_TYPE, FA_LOGIN_TYPE } from './constants';
import { getDirname } from '../../../utils/parseQuery';
import styles from './styles';
import SupportLink from '../../UI/SupportLink';

const useStyles = makeStyles(styles, { name: getDirname(__dirname) });

const ConfirmModal = ({
  handleClose,
  handleSubmit,
  submitting,
  errorStatus,
  clearErrorStatus,
  toggleType,
  type,
  form,
}) => {
  const classes = useStyles();
  const isRescueCodeType = useMemo(() => type === FA_LOGIN_TYPE.RESCUE_CODE, [type]);
  const MODAL_INFO = FA_LOGIN_INFO_BY_TYPE[type] || FA_LOGIN_INFO_BY_TYPE[FA_LOGIN_TYPE.FA_CODE];

  return (
    <CustomModal
      handleClose={handleClose}
      contentClass={classes.modalContent}
      isOpen
      cancelText="common.modals.customModalCancelText"
      submitText="login.confirmMFAModal.submitBtnText"
      onSubmit={handleSubmit}
      titleId="login.confirmMFAModal.title"
      isSubmitting={submitting}
      disableSubmit={submitting}
      formName={form}
    >
      <div>
        <ErrorWithControl errorStatus={errorStatus} clearErrorStatus={clearErrorStatus} />

        <div className={classes.container}>
          <div className={classes.hint}>
            <FormattedMessage id={MODAL_INFO.hint} />
          </div>

          {isRescueCodeType && (
            <div className={classes.warningWrap}>
              <NotificationBlock
                type={notificationsTypes.info}
                message={
                  <FormattedMessage
                    id={MODAL_INFO.warning}
                    values={
                      isRescueCodeType
                        ? {
                            support: (
                              <SupportLink className={classes.supportLinkText}>
                                <FormattedMessage id="deleteProfile.support" />
                              </SupportLink>
                            ),
                          }
                        : undefined
                    }
                  />
                }
              />
            </div>
          )}

          <div className={classes.card}>
            <div>
              <div className={classes.label}>
                <FormattedMessage id={MODAL_INFO.label} />
              </div>

              <div className={cn({ [classes.faCodeFieldWrap]: !isRescueCodeType })}>
                <Field
                  name="code"
                  component={isRescueCodeType ? TextFormField : MFACodeInput}
                  wrapClassName={cn({ [classes.inputWrap]: isRescueCodeType })}
                />
              </div>
            </div>

            <div className={classes.dividerWrap}>
              <Divider>
                <FormattedMessage id="login.loginExistedUserDivider" />
              </Divider>
            </div>

            <div className={classes.toggleButtonWrap}>
              <TextButton onClick={toggleType} additionalClass={classes.toggleButton}>
                <FormattedMessage id={MODAL_INFO.toggleText} />
              </TextButton>
            </div>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

ConfirmModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  errorStatus: PropTypes.shape({}),
  clearErrorStatus: PropTypes.func.isRequired,
  toggleType: PropTypes.func.isRequired,
  type: PropTypes.oneOf(Object.values(FA_LOGIN_TYPE)).isRequired,
  form: PropTypes.string.isRequired,
};

ConfirmModal.defaultProps = {
  errorStatus: null,
};

export default compose(reduxForm({}))(ConfirmModal);
