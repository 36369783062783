const profileInfo = {
  profileInfoPhotoLabel: 'photo',
  profileInfoPhotoDeleteSuccess: 'Profile photo is successfully deleted.',
  profileInfoPhotoUpdateSuccess: 'Profile photo is successfully updated.',
  profileInfoNameLabel: 'name (optional)',
  profileInfoCountryLabel: 'country (optional)',
  profileInfoPhoneLabel: 'phone (optional)',
  profileInfoZoneLabel: 'time zone',
  profileInfoDateLabel: 'date format',
  profileInfoBtn: 'Save changes',
  profileInfoUpdateSuccess: 'Your RAK ID is successfully updated.',
  edit: {
    editProfilePhotoModalUploadBtn: 'Upload photo',
    editProfilePhotoModalError:
      'Invalid photo file. Please note that photo file size is limited to 2MB and only .jpg and .png formats are supported.',
  },
  delete: {
    deleteProfilePhotoModalTitle: 'Delete profile photo',
    deleteProfilePhotoModalNotice: 'Do you want to delete your profile photo?',
  },
};

export default profileInfo;
