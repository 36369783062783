export default function styles(theme) {
  return {
    wrap: {
      display: 'inline-block',
      borderRadius: '50%',
    },
    withBorder: ({ size }) => ({
      border: `1px solid ${theme.palette.primary.light}`,
      ...(size === 'large' ? { padding: '1px' } : {}),
    }),
    avatar: {
      lineHeight: 'unset',
      margin: '2px',
      background: theme.custom.getDarkRgbaColor(0.1),
      color: theme.palette.primary.main,
    },
    small: { height: '28px', width: '28px' },
    medium: { height: '42px', width: '42px' },
    large: {
      height: '56px',
      width: '56px',
    },
  };
}
