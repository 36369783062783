import axios from 'axios';

const { REACT_APP_API_URL: baseURL = '' } = process.env;

export default function httpRequest(config) {
  return httpClient.request(config);
}

const httpClient = axios.create({
  baseURL,
  withCredentials: true,
});
