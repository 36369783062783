import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import useLogout from '../../../../../app/hooks/useLogout';
import { notificationsTypes } from '../../../../../components/UI/Notification/constants';
import LOGOUT_STATUSES from '../../../../Auth/Login/constants';
import RATE_LIMIT_TYPES from '../../../../UserProfile/constants';
import AuthSelectors from '../../../../Auth/selectors';
import API_REQ from '../../../../../api/apiActions';

const useMFAErrorHandler = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [errorStatus, setErrorStatus] = useState(null);
  const { handleLogout } = useLogout();
  const isSignedIn = useSelector(AuthSelectors.getIsSigned());

  const errorActions = useMemo(() => {
    const invalidPasswordHandler = () => {
      setErrorStatus({
        type: notificationsTypes.error,
        message: intl.formatMessage({
          id: 'security.errors.password',
        }),
      });
    };
    const invalidCodeHandler = () => {
      setErrorStatus({
        type: notificationsTypes.error,
        message: intl.formatMessage({
          id: 'security.errors.mfaCode',
        }),
      });
    };
    return {
      InvalidEmailPasswordCode: invalidPasswordHandler,
      InvalidCredentials: invalidPasswordHandler,
      ValidationExceptionCode: invalidPasswordHandler,
      EnableSoftwareTokenMFAException: invalidCodeHandler,
      InvalidVerificationCode: invalidCodeHandler,
      InvalidRescueCode: () =>
        setErrorStatus({
          type: notificationsTypes.error,
          message: intl.formatMessage({
            id: 'security.errors.recoveryCode',
          }),
        }),
      RateLimitCode: async (result) => {
        let logoutStatus;

        if (result?.type === RATE_LIMIT_TYPES.validatePassword) {
          logoutStatus = LOGOUT_STATUSES.limit.mfa.password;
        } else if (result?.type === RATE_LIMIT_TYPES.validateRescueCode) {
          logoutStatus = LOGOUT_STATUSES.limit.recoveryCodes.code;
        } else if (result?.type === RATE_LIMIT_TYPES.validate2faCode) {
          logoutStatus = LOGOUT_STATUSES.limit.mfa.code;
        }

        if (isSignedIn) {
          await handleLogout(logoutStatus);
        } else {
          dispatch({
            type: API_REQ.AUTH.LOGOUT.SUCCESS,
            meta: { logoutStatus },
          });
        }
      },
      default: (result) => {
        setErrorStatus({ type: notificationsTypes.error, ...result });
      },
    };
  }, [dispatch, handleLogout, intl, isSignedIn]);

  const clearErrorStatus = useCallback(() => setErrorStatus(null), []);

  return {
    errorActions,
    errorStatus,
    clearErrorStatus,
  };
};

export default useMFAErrorHandler;
