import React, { Suspense } from 'react';
import PropTypes from 'prop-types';

import Loader from '../../components/UI/Loader';

function RouteWrapper({ centerLoader, children }) {
  return (
    <Suspense fallback={<Loader isAbsoluteCentered={centerLoader} />}>
      {children}
    </Suspense>
  );
}

RouteWrapper.propTypes = {
  centerLoader: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

RouteWrapper.defaultProps = {
  centerLoader: false,
};

export default RouteWrapper;
