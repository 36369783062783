import { linkMixin, textMixin, textSizes } from '../../../app/theme/mixins';

export default function styles(theme) {
  return {
    modalContent: {
      width: '840px',
      [theme.breakpoints.down('sm')]: {
        width: 'auto',
      },
    },
    titleLink: {
      ...linkMixin(),
    },
    content: {
      ...textMixin({
        size: textSizes.medium,
        opacity: 1,
        color: theme.custom.darkColor,
      }),
    },
    footerLink: {
      ...linkMixin(),
      fontSize: '11px',
      lineHeight: '16px',
    },
  };
}
