import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { AppBar, IconButton, Toolbar, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { ReactComponent as Logo } from '../../../assets/icons/logos/logo.svg';
import { ReactComponent as HamburgerIcon } from '../../../assets/icons/other/hamburger.svg';
import useLogoutForm from './hooks/useLogoutForm';
import UserMenu from './components/UserMenu';
import Drawer from './components/Drawer';
import AuthSelectors from '../../../pages/Auth/selectors';
import SinglePageNavigation from './components/SinglePageNavigation';
import { SinglePageLayoutContext } from '../../../app/providers/SinglePageLayoutProvider';
import styles from './styles';
import { getDirname } from '../../../utils/parseQuery';

const useStyles = makeStyles(styles, { name: getDirname(__dirname) });

function Header({ isMobile }) {
  const classes = useStyles();

  const isSignedIn = useSelector(AuthSelectors.getIsSigned());
  const { onSubmit: handleLogout } = useLogoutForm();
  const [isOpen, setOpen] = useState(false);
  const { isSinglePage } = useContext(SinglePageLayoutContext);
  const toggleDrawer = (open) => (event) => {
    event.preventDefault();
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setOpen(open);
  };

  useEffect(() => {
    if (!isMobile) {
      toggleDrawer(false)({ preventDefault: () => {} });
    }
  }, [isMobile]);

  return (
    <div className={classes.appHeader}>
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          {isMobile && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer(true)}
              edge="start"
            >
              <HamburgerIcon />
            </IconButton>
          )}
          <div
            className={cn(classes.logoWrapper, {
              [classes.singlePageLogoWrapper]: isSinglePage,
            })}
          >
            <Logo className={classes.logo} />
            <span className={classes.logoText}>
              <FormattedMessage id="common.header.headerLogoText" />
            </span>
          </div>
          {isSignedIn && !isSinglePage && <UserMenu handleLogout={handleLogout} />}
          {isSinglePage && !isMobile && <SinglePageNavigation />}
        </Toolbar>
      </AppBar>

      <Drawer isOpen={isOpen} toggleDrawer={toggleDrawer} />
    </div>
  );
}

Header.propTypes = {
  isMobile: PropTypes.bool.isRequired,
};

export default Header;
