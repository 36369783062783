import PropTypes from 'prop-types';

const FIELD_WRAP_PROP_TYPES = {
  RULES: {
    description: PropTypes.node,
    error: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        id: PropTypes.string,
        values: PropTypes.shape({
          value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        }),
      }),
    ]),
    touched: PropTypes.bool,
    children: PropTypes.node.isRequired,
    label: PropTypes.node,
    tooltipId: PropTypes.string,
  },
  DEFAULTS: {
    touched: false,
    description: '',
    error: '',
    label: null,
    tooltipId: '',
  },
};

export default FIELD_WRAP_PROP_TYPES;
