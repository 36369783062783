const tooltips = {
  tooltipEmail:
    'Your e-mail address becomes your RAK ID and is used to sign in to all RAK products and services.',
  tooltipPasswordLength: 'Should be at least 12 characters long',
  tooltipHasSpecialCharacter: 'Has at least one special character',
  tooltipHasNumber: 'Has at least one number',
  tooltipHasLatinLatter: 'Has at least one latin letter',
};

export default tooltips;
